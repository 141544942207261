<div class="container">
  <div class="row mt-3">
    <div class="col">
      <div class="card">
        <h2 class="card-header">
          Outcome History - Criteria & Affordability
        </h2>
        <div class="card-body">
          <form [formGroup]="outcomeHistorySearchForm">
            <fieldset>
              <legend>Search</legend>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="surname" class="sr-only">By surname</label>
                    <input
                      type="text"
                      id="surname"
                      placeholder="By surname"
                      class="form-control"
                      formControlName="surname"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="postcode" class="sr-only">By postcode</label>
                    <input type="text" class="form-control" placeholder="By postcode" formControlName="postcode" id="postcode"/>
                  </div>
                </div>
              </div>
            </fieldset>
          </form>
          <div class="row my-3">
            <div class="col d-flex justify-content-end">
              <img src="assets/images/2022-ignite/ignite_product_icon.svg" class="img-fluid ignite-logo" />
              <span class="my-auto ms-2">Products</span>
              <img src="assets/images/2022-ignite/ignite_affordability_icon.svg" class="img-fluid ignite-logo ms-3 me-1" />
              <span class="my-auto ms-2">Affordability</span>
              <img src="assets/images/2022-ignite/ignite_criteria_icon.svg" class="img-fluid criteria-logo ms-3 me-1" />
              <span class="my-auto ms-2">Criteria</span>
              <img src="assets/images/2022-ignite/ignite_property_icon.svg" class="img-fluid criteria-logo ms-3 me-1" />
              <span class="my-auto ms-2">Auto Property Checks</span>
            </div>
          </div>
          <div class="table-container" style="max-height: 612px;">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Created</th>
                  <th scope="col">Surname</th>
                  <th scope="col">Postcode</th>
                  <th scope="col">Products Used</th>
                  <th scope="col">Lending Type</th>
                  <th scope="col">Days Remaining</th>
                  <th class="col">Notes</th>
                  <th class="col">Edit Notes</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of filteredOutcomeHistory | async; index as i">
                  <td attr.aria-label="{{ item.createdDate | date: 'dd LLLL yyyy' }}">{{ item.createdDate | date: 'dd/MM/yyyy' }}</td>
                  <td>{{ item.surname }}</td>
                  <td>{{ item.postcode }}</td>
                  <td>
                    <div class="d-flex">
                      <div class="col px-0">
                        <img src="assets/images/2022-ignite/ignite_product_icon.svg" class="img-fluid ignite-logo" [ngClass]="!isProductsCriteria(item) ? 'grayscale' : null" />
                      </div>
                      <div class="col px-0">
                        <img src="assets/images/2022-ignite/ignite_affordability_icon.svg" class="img-fluid ignite-logo" [ngClass]="!isIgnite(item) ? 'grayscale' : null" />
                      </div>
                      <div class="col px-0">
                        <img src="assets/images/2022-ignite/ignite_criteria_icon.svg" class="img-fluid criteria-logo" [ngClass]="!isCriteria(item) ? 'grayscale' : null" />
                      </div>
                      <div class="col px-0">
                        <img src="assets/images/2022-ignite/ignite_property_icon.svg" class="img-fluid criteria-logo" [ngClass]="!isProperty(item) ? 'grayscale' : null" />
                      </div>
                    </div>
                  </td>
                  <td>{{ item.lendingType }}</td>
                  <td align="center">{{ item.daysRemaining }}</td>
                  <td class="notes-col">{{ item.notes }}</td>
                  <td>
                    <button title="Edit" class="btn no-show-button" (click)="edit(item)">
                      <i class="far fa-edit"></i>
                    </button>
                  </td>
                  <td>
                    <div class="d-flex">
                      <button  title="View" class="btn no-show-button width-38" (click)="openReport(item)">
                        <!-- View -->
                        <i class="far fa-eye"></i>
                      </button>
                      <ng-container *ngIf="deletingRow !== i" >
                        <button  title="Delete" class="btn no-show-button width-38" (click)="setDeletingRow(i)">
                          <!-- Delete -->
                          <i class="fas fa-trash"></i>
                        </button>
                      </ng-container>
                      <ng-container *ngIf="deletingRow === i">
                        <div class="d-flex width-38">
                          <button  title="No" class="btn no-show-button col" (click)="setDeletingRow()">
                            <!-- Delete No -->
                            <i class="fas fa-times"></i>
                          </button>
                          <button  title="Yes" class="btn no-show-button col" (click)="deleteHistoryRow(item)">
                            <!-- Delete Yes -->
                            <i class="fas fa-check"></i>
                          </button>
                        </div>
                      </ng-container>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p class="text-end">
            Showing {{ (filteredOutcomeHistory | async).length < allOutcomeHistory.value.length ? (filteredOutcomeHistory | async).length + ' of' : 'all' }} {{ allOutcomeHistory.value.length }} saved searches
          </p>
        </div>
      </div>
      <div *ngIf="isClubHubGenerateEsis" class="card mt-3 mb-5">
        <h2 class="card-header">
          Outcome History - Documents
        </h2>
        <div class="card-body">
          <form [formGroup]="documentSearchForm">
            <fieldset>
              <legend>Search</legend>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="surname" class="sr-only">By Client Name</label>
                    <input
                      type="text"
                      id="surname"
                      placeholder="By surname"
                      class="form-control"
                      formControlName="surname"
                    />
                  </div>
                </div>
              </div>
            </fieldset>
          </form>
          <p>Saved records are stored in outcome history for 180 days from creation.</p>
          <div class="table-container" style="max-height: 619px;">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">
                    <button type="button" class="btn btn-2022--secondary" (click)="confirmDeleting()" [attr.disabled]="deleteCount === 0 ? 'disabled' : null">
                      Delete {{ this.deleteCount > 1 ? ' Records' : ' Record' }}
                    </button>
                  </th>
                  <th scope="col">Created</th>
                  <th scope="col">Client Name</th>
                  <th scope="col">Postcode</th>
                  <th scope="col">Lending Type</th>
                  <th scope="col">Days Remaining</th>
                  <th scope="col">Documents column</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of documents | async; index as i">
                  <td style="height: 0">
                    <div class="d-flex h-100 align-items-center justify-content-center">
                      <input
                        type="checkbox"
                        [(ngModel)]="item.deleteSelected"
                        #ctrl="ngModel"
                        class="form-check-input m-0">
                    </div>
                  </td>
                  <td attr.aria-label="{{ item.createdDateUtc | date: 'dd LLLL yyyy' }}">
                    {{ item.createdDateUtc | date: 'dd/MM/yyyy' }}
                  </td>
                  <td>{{ getClientNamesDisplay(item) }}</td>
                  <td>{{ item.clientPostCode }}</td>
                  <td>{{ item.lendingType }}</td>
                  <td>{{ item.daysRemaining }}</td>
                  <td>
                    <div class="d-flex justify-content-center">
                      <ng-container>
                        <button class="btn no-show-button me-1 width70" (click)="downloadDocument(generatedLendingDocumentType.Eor, item.documentId)">
                          <i class="fas fa-download"></i>
                          EOR
                        </button>
                      </ng-container>
                      <ng-container>
                        <button class="btn no-show-button width70" (click)="downloadDocument(generatedLendingDocumentType.Esis, item.documentId)">
                          <i class="fas fa-download"></i>
                          ESIS
                        </button>
                      </ng-container>
                    </div>
                  </td>
              </tbody>
            </table>
          </div>
          <p class="text-end">
            Showing {{ (documents | async).length < allDocuments.value.length ? (documents | async).length + ' of' : 'all' }} {{ allDocuments.value.length }} saved documents
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #saveOutcomeModalTemplateRef>
  <lib-save-outcome-modal [editItem]="editItem" [notesOnly]="true"></lib-save-outcome-modal>
</ng-template>
