import { AbstractControl, AsyncValidatorFn, NgForm, UntypedFormGroup, ValidatorFn } from '@angular/forms';

export type ErrorMessageHandler = string | ((control: AbstractControl, errorValue: unknown) => string);

export enum FieldTypes {
  Textbox = 'input',
  InputGroup = 'inputgroup',
  FileUpload = 'file',
  FilePathUpload = 'filepath',
  Password = 'password',
  Email = 'email',
  Number = 'number',
  Date = 'date',
  Time = 'time',
  TextArea = 'textarea',
  RadioList = 'radiolist',
  Select = 'select',
  Search = 'search',
  Checkbox = 'checkbox',
  CheckboxList = 'checkboxlist',
  Button = 'button',
  ButtonGroup = 'buttongroup',
  Link = 'link',
  Toggle = 'toggle',
  Heading = 'heading',
  Paragraph = 'paragraph',
}

export interface FieldOption {
  value: string | number;
  text: string;
  selected?: boolean;
  disabled?: boolean;
  hidden?: boolean;
}

export interface IFieldConfig {
  name: string;
  label?: string;
  errorLabel?: string;
  cancelButtonLabel?: string;
  disabled?: boolean;
  options?: FieldOption[];
  hasPlaceholder?: boolean;
  placeholder?: string;
  type: FieldTypes;
  cssClass?: string;
  focus?: boolean;
  validation?: ValidatorFn[];
  asyncValidation?: AsyncValidatorFn[];
  value?: unknown;
  hidden?: boolean;
  tooltip?: string;
  clicked?: boolean;
  additionalText?: string;
  onSubmit?: (form: NgForm | UntypedFormGroup) => void;
  onCancel?: () => void;
  errorMessages?: Record<string, ErrorMessageHandler>;
  inputGroupAddOnLabel?: string;
  inputGroupAddOnHandler?: unknown;
  onSelectChange?: (arg0: string) => void;
  onRadioChange?: (arg0: string) => void;
  onToggleChange?: (arg0: boolean) => void;
  clickHandler?: any;
  rows?: number;
}

export interface Field {
  config: IFieldConfig;
}
