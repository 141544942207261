export enum FeeScaleConditionType {
  None = 0,
  ExpatNotInUk = 1,
  HelpToBuy = 2,
  Hmo = 3,
  Purchase = 4,
  Remortgage = 5,
  SelfBuild = 6,
}

export interface ConditionalFeeScale<TFee> {
  feeType: TFee;
  condition: FeeScaleConditionType;
  fee: number | null;
}
