<ng-container [ngSwitch]="elementType">
  <!-- For input based types (e.g. string, number) -->
  <input
    *ngSwitchCase="'input'"
    class="form-control form-control-sm"
    [placeholder]="label ?? ''"
    [appAttrFromObj]="inputAttributes"
    [(ngModel)]="value"
    (ngModelChange)="onValueChange($event)"
    (keydown)="onInputKeyDown($event)">

  <!-- For dropdown based types (e.g. enums) -->
  <select
    *ngSwitchCase="'select'"
    class="form-select form-select-sm"
    [(ngModel)]="value"
    (ngModelChange)="onValueChange($event)">
    <option *ngFor="let option of selectOptions" [ngValue]="option.value">
      {{option.displayName}}
    </option>
  </select>
</ng-container>
