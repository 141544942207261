<div class="input-group m-0">
  <input
    type="text"
    class="form-control"
    [class.is-invalid]="showError"
    [formControl]="formControl"
    [formlyAttributes]="field"
  />
  <button *ngIf="props.submitText" type="button" class="btn btn-2022--secondary text-nowrap" (click)="props.clickHandler(field)">
    {{ props.submitText }}
    <span class="sr-only" *ngIf="!props.submitText">{{ key }}</span>
  </button>
</div>

<ng-container *ngIf="props?.linkText">
  <div class="row pt-3 pb-2">
    <div class="col-12">
      <a href="javascript:void(0)" class="text-small" (click)="props.setAddressType()">{{ props.linkText }}</a>
    </div>
  </div>
</ng-container>