<div *ngIf="!statusMessage" class="preview-section">
  <ng-container [ngSwitch]="currentPage">
    <ng-container *ngSwitchCase="previewUploadModalPage.Preview">
      <div *ngIf="uploadResult.warnings.length" class="alert alert-warning" role="alert">
        <ul>
          <li *ngFor="let warning of uploadResult.warnings">{{ warning }}</li>
        </ul>
      </div>

      <div class="preview-table-container">
        <lib-simple-grid
          [columns]="previewTableColumns"
          [datasource]="uploadResult.products"
          [defaultColumnSortable]="false"
          [paginate]="false"
          [sticky]="true"
          [headerCellClassList]="'px-2 py-1 bg-2022--primary text-white ws-nowrap'"
          [rowCellClassList]="'px-2 py-1'"
        />
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="previewUploadModalPage.VariableRateCodes">
      <div *ngIf="existingVariableRateScheduledDate" class="alert alert-warning" role="alert">
        <i class="fas fa-exclamation-triangle"></i>
        You currently have a new set of revert rates scheduled to go live on {{ existingRevertRateScheduledDateFormatted }}.
        By uploading these products, the existing schedule will be removed.
      </div>
      <lib-variable-rate-codes-editor
        [form]="variableRateCodeForm"
        [variableRateCodes]="variableRateCodes"
        [scheduledDate]="existingVariableRateScheduledDate"
      />
    </ng-container>
  </ng-container>

  <lib-event-schedule *ngIf="isFinalPage" [scheduleForm]="scheduleForm" alignItems="end" />

  <div class="row preview-buttons">
    <div class="col">
      <button class="preview-cancel btn btn-2022--secondary" (click)="goBack()">Go back</button>
    </div>
    <div class="col">
      <button class="preview-submit btn btn-2022--secondary" (click)="continue()">{{ continueButtonLabel }}</button>
    </div>
  </div>
</div>

<div *ngIf="statusMessage && inputsLoaded" class="status-section">
  <div *ngIf="!errorOccurred" class="alert alert-success" role="alert">
    <div>
      <p *ngFor="let para of statusMessage">{{ para }}</p>
    </div>
    <div class="text-end">
      <button class="btn btn-2022--secondary" (click)="this.close()">Continue</button>
    </div>
  </div>
  <div *ngIf="errorOccurred" class="alert alert-danger" role="alert">
    <p *ngFor="let para of statusMessage">{{ para }}</p>
    <ul *ngIf="validationErrors">
      <li *ngFor="let errorMessage of validationErrors">
        {{ errorMessage }}
      </li>
    </ul>
  </div>
  <div *ngIf="feesMissingInEsis?.length > 0" class="alert alert-danger" role="alert">
    <p>Please update your ESIS data form - costs to be paid on a one-off basis Section to include the following fee type:</p>
    <ul>
      <li *ngFor="let missingFee of feesMissingInEsis">
        {{ missingFee.fee }}, {{missingFee.lendingTypeDocumentType}}
      </li>
    </ul>
    <div class="text-end">
      <a
        class="btn btn-2022--secondary me-2"
        (click)="amendEsisFees()"
      >
        Amend now
      </a>
    </div>
  </div>
</div>
