export interface Lender {
  id: number;
  lenderNameId: number;
  lenderText: string;
  lenderNotes: string;
  flowType: string | undefined;
  lenderUrl: string;
  lenderLogoUrl: string;
  affordabilityCalculatorUrl: string;
  lendersAffordabilityCalculatorUrl: string;
  lenderDetailsUrl: string;
  affordabilityCalculatorOn: boolean;
  criteriaEnabled: boolean;
  productsEnabled?: boolean;
  archived: boolean;
  source: number;
}

export enum LenderProductHandlerType {
  Automatic = 0,
  Standard = 1,
  Bespoke = 2,
}

export interface LendersAbilityResponse {
  affordabilityLenders: string[];
  productLenders: string[];
}
export interface LenderNameLender {
  lendingTypeId: number;
  lenderId: number;
}
export interface LenderName {
  lenderNameId: number;
  lenderName: string;
  lenderLogoUrl: string;
  lenderDetailsUrl: string;
  lenders: LenderNameLender[];
  name: string;

  // Additional lender properties
  hasProducts: boolean;
  hasLiveProducts: boolean;
  hasSchedule: boolean;
  separateExclusiveUpload: boolean;
  resiBtlProductHandlerType: LenderProductHandlerType;
}

export interface ProcFeeLender {
  id: number;
  lender: string;
  mortgageAccountNumberFormatPattern: string;
  mortgageAccountNumberFormatDescription: string;
}

export interface LenderOptions {
  outcomeId: number;
  outcomeResult: string;
  id: number;
  parentId?: number;
  questionText: string;
  questionDescription: string;
  hierarchyId: number;
  flowType: string;
}

export interface LenderContextNotes {
  lenderId: number;
  contextId: number;
  contextNotes: string;
}

export interface LenderChanges {
  options: LenderOptions[];
  savedOptions: LenderOptions[];
  lenderId: number | undefined;
}

export interface LenderFlow {
  lenderId: number | null;
  hierarchyId: number;
  flowType: string;
}

export interface LenderNetwork {
  lenderId: number;
  networkId: number;
  network: string;
  checked: boolean;
}

export interface LenderProducts {
  id: number;
  archived: boolean;
  createdDate: string;
  lenderCode: string;
  liveFrom: string;
  liveTo: string;
  procFeeLender: number;
  procFeeLenderId: string;
  productDescription: string;
  updatedDate: string;
}

export enum LenderProductSource {
  Asanto = 0,
  Database = 1,
}

export interface LenderProductsConfig {
  hasProducts: boolean;
  showProducts: boolean;
  showTrueCost: boolean;
  lenderProductSource: LenderProductSource;
  resiBtlLenderProductHandlerType: LenderProductHandlerType;
  resiBtlExclusiveProductHandlerType: LenderProductHandlerType | null;
  separateExclusiveUpload: boolean;
}

export interface LenderProductsConfigWithValidation extends LenderProductsConfig {
  validationErrors: string[];
}

export interface ArchivedLenders {
  id: number;
  name: string;
}

export interface LenderNameAndId {
  id: number;
  name: string;
}

export interface OtherLenderType {
  id: number;
  lenderNameId: number;
  flowType: string;
}

export interface LendingTypeViewModel {
  lenderId: number;
  lendingTypeId: number;
  active: boolean;
}

export interface LenderNameViewModel {
  lenderNameId: number;
  lenderText: string;
  internalLenderCode: string;
  externalLenderCode: string;
  lenderNameVariations: string[];
  lendingTypes: LendingTypeViewModel[];
  onBoarded: boolean;
  packager: boolean;
  archived: boolean;
}

export interface LenderLendingType {
  lenderId: number | undefined;
  lendingTypeId: number;
  name: string;
  checked: boolean;
}

export enum ProductUploadType {
  Core = 1,
  Exclusive = 2,
}

export interface ProductUpload {
  id: number;
  uploadDate: string;
  scheduleDate: string;
  isImmediate: boolean;
  uploadType: ProductUploadType;
  uploadedBy: string | null;
  successful: boolean;
  errors: string | null;
  canDownload: boolean;
}
