import { Component, Input } from '@angular/core';
import { Availability, Product } from 'apps/shared/src/models';

@Component({
  selector: 'lib-type-product-column',
  templateUrl: 'type-product-column.component.html',
  styleUrls: ['type-product-column.component.scss'],
})
export class TypeProductColumnComponent {
  @Input() public product: Product;
  @Input() public useProductTransferAvailability: boolean;

  public get isProductTransfer() {
    if (this.useProductTransferAvailability) {
      return this.product.productTransfer === Availability.AlsoAvailable
        || this.product.productTransfer === Availability.OnlyAvailable;
    }

    return this.product.productTransferEligible;
  }
}
