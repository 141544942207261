<select
  class="form-select"
  [class.is-invalid]="showError"
  [formControl]="formControl"
  [formlyAttributes]="field"
  [class.disabled]="!!props.disabled"
  [class.readonly]="!!props.readonly"
  [attr.data-testid]="props.testId || field.key"
  [attr.tabindex]="!!props.readonly ? -1 : 0"
>
  <option [hidden]="!props.allowNull" [ngValue]="null">
    {{ props.placeholder }}
  </option>

  <option
    *ngFor="let option of props.options"
    [ngValue]="option.value"
    [disabled]="!!option.disabled"
    [attr.data-testid]="props.testId ? props.testId + '_option_' + option.value : field.key + '_option_' + option.value"
  >
    {{ option.label }}
  </option>
</select>