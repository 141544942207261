<div class="condition-editor-grid">
  <!-- Property operand -->
  <lib-rules-engine-property-selector
    class="property"
    [properties]="properties"
    [propertyName]="condition?.propertyName ?? ''"
    (propertyNameChange)="setProperty($event)" />

  <!-- Operator -->
  <select
    *ngIf="availableOperators.length"
    class="operator form-select form-select-sm"
    [ngModel]="condition.operatorName"
    (ngModelChange)="setOperator($event)">
    <option
      *ngFor="let operator of availableOperators; trackBy: trackByName"
      [ngValue]="operator.name">
      {{operator.name}}
    </option>
  </select>

  <!-- Right-hand operands -->
  <div class="operands row m-0">
    <div class="col p-0" *ngFor="let operand of rightHandOperands; let index = index; trackBy: trackByIndex">
      <lib-rules-engine-value-editor
        [type]="schema.types.get(operand.typeName)"
        [label]="operand.displayName"
        [nullable]="operand.isNullable"
        [value]="getOperand(index)"
        (valueChange)="setOperand(index, $event)"
      />
    </div>
  </div>
</div>
<ng-container *ngIf="showErrors">
  <p class="text-2022--danger m-0" *ngFor="let errorMessage of errorMessages">{{errorMessage}}</p>
</ng-container>
