<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <table class="fee-table">
        <tr class="header-row">
            <th>Fee Name</th>
            <th>Description</th>
            <th>Fee</th>
            <th>Fee Configuration</th>
        </tr>
        <ng-container formArrayName="fees">
        <tr *ngFor="let fee of feeArray?.controls; let i = index"  [formGroupName]="i">
                <td class="name">{{feeArray.controls[i].value.name}}</td>
                <td class="description" [innerHTML]="feeArray.controls[i].value.description"></td>
                <td class="amount">
                    <input [class.is-invalid]="invalid(['fees',i, 'amount']) && errors(['fees',i, 'amount']).max" class="form-control" type="textbox" id="amount" formControlName="amount"  placeholder="0.00" appTwoDigitDecimalNumber>
                    <div *ngIf="invalid(['fees',i, 'amount']) && errors(['fees',i, 'amount']).max" class="text-danger">
                        Fee amount must be less than £100,000
                    </div>
                </td>
                <td  class="configuration">
                    <div formGroupName="feeConfiguration">
                        <div class="form-group">
                            <input [class.is-invalid]="invalid(['fees',i]) && errors(['fees',i]).invalidFeeConfiguration" type="checkbox" class="form-check-input me-1" formControlName="purchase">
                            <label class="form-check-label">Purchase</label>
                        </div>
                        <div class="form-group">
                            <input [class.is-invalid]="invalid(['fees',i]) && errors(['fees',i]).invalidFeeConfiguration" type="checkbox" class="form-check-input me-1" formControlName="remortgage">
                            <label class="form-check-label">Remortgage</label>
                        </div>
                        <div class="form-group">
                            <input [class.is-invalid]="invalid(['fees',i]) && errors(['fees',i]).invalidFeeConfiguration" type="checkbox" class="form-check-input me-1" formControlName="productTransfer">
                            <label class="form-check-label">Product Transfer</label>
                        </div>
                        <div class="form-group">
                            <input [class.is-invalid]="invalid(['fees',i]) && errors(['fees',i]).invalidFeeConfiguration" type="checkbox" class="form-check-input me-1" formControlName="furtherAdvance">
                            <label class="form-check-label">Further Advance</label>
                        </div>
                        <div class="form-group">
                            <input [class.is-invalid]="invalid(['fees',i]) && errors(['fees',i]).invalidFeeConfiguration" type="checkbox" class="form-check-input me-1" formControlName="existingCustomer">
                            <label class="form-check-label">Existing Customer</label>
                        </div>
                        <div class="form-group">
                            <input [class.is-invalid]="invalid(['fees',i]) && errors(['fees',i]).invalidFeeConfiguration" type="checkbox" class="form-check-input me-1" formControlName="secondCharge">
                            <label class="form-check-label">Second Charge</label>
                        </div>
                    </div>
                    <div *ngIf="invalid(['fees',i]) && errors(['fees',i]).invalidFeeConfiguration" class="text-danger">
                        At least one option is required.
                    </div>
                </td>
        </tr>
    </ng-container>
    </table>
    <div class="d-flex justify-content-end">
        <button type="submit" class="btn btn-2022--secondary mt-3">Submit</button>
    </div>
</form>
