import { Component, Input } from '@angular/core';
import { AuditStatus } from '@msslib/models/audits';
import { StartCasePipe } from '@msslib/pipes';

interface AuditLogPartial {
  status: AuditStatus;
  to: Record<string, unknown> | null;
  from: Record<string, unknown> | null;
}

type AuditLogChanges = Record<'name' | 'from' | 'to', string>[];

@Component({
  selector: 'lib-audit-log-change-list',
  templateUrl: 'audit-log-change-list.component.html',
})
export class AuditLogChangeListComponent {
  @Input() public row: AuditLogPartial;
  @Input() public entityTypeName = 'entity';
  @Input() public fieldNameFormatter: ((fieldName: string) => string | null) | undefined;
  public readonly auditStatus = AuditStatus;

  public constructor(
    private readonly startCasePipe: StartCasePipe,
  ) { }

  public enumerateChanges(row: AuditLogPartial): AuditLogChanges {
    const f = (v: unknown) => {
      switch (true) {
        case v === null: return '-Nothing-';
        case typeof v === 'number': return (v as number).toString();
        case typeof v === 'boolean': return v ? 'Yes' : 'No';
        default: return `"${v}"`;
      }
    };
    return Object.keys(row.from ?? row.to ?? {}).map(prop => ({
      name: this.fieldNameFormatter?.(prop) ?? this.startCasePipe.transform(prop),
      from: f(row.from?.[prop]),
      to: f(row.to?.[prop]),
    }));
  }
}
