import { IgniteResponse } from 'apps/clubhub/src/app/ignite/models';
import { CriteriaType, ICriteriaUpdateItem, LendingTypeCode, SearchType } from 'apps/shared/src/models';

import {
  LenderNameApplicationToOfferDefinition,
  LenderNameCreditType,
  LenderNameTimePeriod,
  MiSaveOutcomeModel,
  OutcomeResults,
} from '@msslib/models';
import { MatchedLenderProducts } from './matched-product';
import { QuestionType } from '@msslib/models/criteria-issue';

export function igniteIsSuitable(outcome: string | undefined, isCriteriaV2: boolean) {
  if (isCriteriaV2) {
    // criteria v2 rules
    switch (outcome) {
      case OutcomeResults.Yes:
        return { outcome: 'Yes', cssClass: 'text-dark-blue' };
      case OutcomeResults.No:
        return { outcome: 'No', cssClass: 'text-dark-blue' };
      case OutcomeResults.Refer:
      case OutcomeResults.Unset:
        return { outcome: 'Refer', cssClass: 'text-warning' };
      default:
        return { outcome: '', cssClass: 'text-dark-blue' };
    }
  }
  // criteria v1 rules - can be removed when criteria v2 goes live
  switch (outcome) {
    case OutcomeResults.Yes:
      return { outcome: 'Yes', cssClass: 'text-dark-blue' };
    case OutcomeResults.No:
      return { outcome: 'No', cssClass: 'text-dark-blue' };
    case OutcomeResults.Refer:
      return { outcome: 'Refer', cssClass: 'text-warning' };
    default:
      return { outcome: '', cssClass: 'text-dark-blue' };
  }
}
export interface INode {
  id: number | undefined;
  text: string | undefined;
}

export class OutcomeDetailsCard {
  public answerId: number;
  public context: string | undefined;
  public contextId?: number;
  public issue: string;
  public issueId?: number;
  public criteriaContextLendingTypeId?: number;
  public criteriaContextVersionId?: number;
  public outcomeType?: CriteriaType;
  public questionsAndAnswers: {
    question: string | undefined;
    answer: string | any | undefined;
    answerName?: string;
    questionId?: number;
    answerId?: number;
    answerType?: QuestionType;
    answers?: INode[];
    answerUnits?: string;
  }[];
  public static card(card: OutcomeDetailsCard, endOfLabelText: string) {
    return {
      answerId: card.answerId,
      context: card.context,
      outcomeType: card.outcomeType,
      contextId: card.contextId,
      issueId: card.issueId,
      issue: `${card.issue}${endOfLabelText}`,
      criteriaContextLendingTypeId: card.criteriaContextLendingTypeId,
      criteriaContextVersionId: card.criteriaContextVersionId,
      questionsAndAnswers: card.questionsAndAnswers.map(cq => {
        return {
          question: cq.question,
          answer: cq.answer,
          answerType: cq.answerType,
          answerId: cq.answerId,
          questionId: cq.questionId,
          answers: cq.answers,
          answerName: cq.answerName,
        };
      }),
    };
  }
}

export class OutcomeLenderContextNote {
  public title: string;
  public notes: string[];
}

export interface OutcomeResponseOverallResult {
  lenderId: number;
  lender: string;
  lenderUrl: string;
  lenderLogoUrl: string;
  affordabilityCalculatorUrl: string;
  lenderNotesIndex: number;
  lenderNotes: string;
  outcomeResult: string;
  verified: boolean;
  canAcceptApplicantOnline: boolean;
  canAcceptOnlineDipAip: boolean;
  canUploadDocumentsOntoWebsite: boolean;
  hasLiveChat: boolean;
  hasManualUnderwriter: boolean;
  creditType?: LenderNameCreditType;
  applicationToOfferDefinition?: LenderNameApplicationToOfferDefinition;
  applicationToOfferTime?: number;
  applicationToOfferPeriod?: LenderNameTimePeriod;
  initialCaseAssessmentTime?: number;
  initialCaseAssessmentPeriod?: LenderNameTimePeriod;
  isAffordabilityCalculatorOn: boolean;
  lenderContextNotes: OutcomeLenderContextNote[];
  outcomeNotes: string[];
}

export class OutcomeHistoryDetailsCard {
  public answerId: number;
  public context: INode;
  public issue: INode;
  public outcomeType?: CriteriaType;
  public questionsAndAnswers: {
    question: INode;
    answer: INode;
    answers?: INode[];
    answerType?: QuestionType; }[];
  public autoAdded: boolean;
  public criteriaContextLendingTypeId?: number;
  public criteriaContextVersionId?: number;
  public static card(card: OutcomeHistoryDetailsCard, endOfLabelText: string) {
    return {
      answerId: card.answerId,
      context: card.context.text,
      outcomeType: card.outcomeType,
      contextId: card.context.id,
      issueId: card.issue.id,
      issue: `${card.issue.text} ${endOfLabelText}`,
      criteriaContextLendingTypeId: card.criteriaContextLendingTypeId,
      criteriaContextVersionId: card.criteriaContextVersionId,
      questionsAndAnswers: card.questionsAndAnswers.map(cq => {
        return {
          question: cq.question.text,
          answer: cq.answer.text,
          answerType: cq.answerType,
          answerId: cq.answer.id,
          questionId: cq.question.id,
          answers: cq.answers,
          answerName: cq.answer.text,
        };
      }),
    };
  }
}

export interface OutcomeResponseContextResult {
  autoAdded: boolean;
  outcomeResult: string;
  outcomeId: number;
  contextId: number;
  context: string;
  verified: boolean;
  isProperty: boolean;
  contextGroup: boolean;
  contextGroupContextIds: number[];
  criteriaType?: CriteriaType;

  // client side only properties
  showDetails?: boolean;
}

export interface SkipListResponseResult {
  name: string;
  value: string;
}

export interface OutcomeResponse {
  outcomeContextCards?: OutcomeDetailsCard[];
  overallResult: OutcomeResponseOverallResult;
  contextResults: OutcomeResponseContextResult[];
  skipList: SkipListResponseResult[];
}
export interface AffordabilityResult {
  productType: LendingTypeCode;
  productTypeName: string;
  affordabilityResults: IgniteResponse[];
  intuitiveCriteria: OutcomeResponse[];
  additionalCriteria: OutcomeResponse[];
  autoAddedOutcomeIds: number[];
  uiModelJson: string;
  saveOutcomeModel: MiSaveOutcomeModel;
  products: MatchedLenderProducts[];
  outcomeContextCards?: OutcomeHistoryDetailsCard[];
  intuitiveOutcomesContextCards?: OutcomeHistoryDetailsCard[];
  criteriaVersion: number;
  createdDate: Date;
}

export interface OutcomeDetails {
  dateTime: string;
  contexts: OutcomeDetailsCard[];
  details: OutcomeResponse[];
  lendingType: string;
  autoAddedOutcomeIds: number[];
  criteriaSearchId: string | null;
}

export interface OutcomeDetailsDetailed {
  dateTime: string;
  contexts: OutcomeHistoryDetailsCard[];
  details: OutcomeResponse[];
  intuitiveDetails: OutcomeResponse[];
  criteriaSearchId: string;
  autoAddedOutcomeIds: number[];
}

export interface PropertyOutcomeDetailsDetailed {
  dateTime: string;
  contexts: OutcomeHistoryDetailsCard[];
  details: OutcomeResponse[];
  intuitiveDetails: OutcomeResponse[];
  propertySearchId: string;
  autoAddedOutcomeIds: number[];
}


export interface OutcomeResult {
  id: number;
  lender: string;
  outcomeResult: string;
  outComeId: number;
  lenderId: number;
}

export interface OutcomeLoginModel {
  verifiableCode: number;
  key: string;
}

export interface OutcomeLoginModelResponse {
  outcomeHistoryId: number;
  verifiableCode: number;
}

export interface OutcomeHistoryViewModel {
  id: number;
  createdDate: Date;
  answerId: number;
  surname: string;
  postcode: string;
  agencyNumber: number;
  criteriaSearchId: string;
  affordabilitySearchId: string;
  productsSearchId: string;
  daysRemaining: string;
  appNames: string[];
  notes: string;
  lendingType: string;
  autoAdded: boolean;
  hasManualAdded: boolean;
  criteriaV2Search?: boolean;
  source: SearchType;
}

export interface IOutcomeQuestionsResultContext {
  lenderOutcomes: ICriteriaUpdateItem[];
  lenderConsidered: boolean;
  contextName: string;
}
export type IOutcomeQuestionsResult = Record<number, {
    results: IOutcomeQuestionsResultContext | null;
    extended?: boolean;
    loading?: boolean;
    group?: boolean;
  }>;

export type IOutcomeQuestionsGroupResult = Record<number, {
    results: IOutcomeQuestionsResultContext[];
    extended?: boolean;
    loading?: boolean;
    group?: boolean;
  }>;

export interface CriteriaLenders {
  lenderName?: string | null;
  lenderLogoUrl?: string | null;
}
export interface CriteriaResponse {
    pollJobID: number | null;
    criteriaSearchId: string;
    quotes: IgniteResponse[];
    errorMessage: string;
    lenders?: CriteriaLenders[];
}
