<div class="row">
  <div class="col d-flex">
    <h5 *ngIf="title">
      {{ title }}
    </h5>
    <figure class="figure bg-white p-2 border rounded mx-auto" *ngIf="imageSource">
      <img [attr.src]="imageSource" [style]="'width:' + +width + 'px;'" class="figure-img img-fluid" />
    </figure>
  </div>
</div>

<div class="row mt-2">
  <div class="col d-flex">
    <div class="mx-auto">
      <button class="btn btn-2022--secondary btn-sm" (click)="onSave(input)" *ngIf="showUploadButton && fileSrc" [disabled]="!widthValid || !aspectValid ? 'disabled' : null">
        Upload
      </button>
      <button class="btn btn-warning btn-sm" (click)="input.click()">
        <input type="file" (change)="fileChange(input)" #input hidden />
        Browse
      </button>
      <button class="btn btn-danger btn-sm" *ngIf="fileSrc || src" (click)="onRemove(input)">
        Remove
      </button>
    </div>
  </div>
</div>
