<div [ngClass]="['btn-group', underLine ? 'underlined': '']" [id]="'btn-group-' + id" role="group" >
  <ng-container *ngFor="let item of list; trackBy: trackByFn">
    <input
      [ngModel]="value"
      class="btn-check radiobutton"
      type="radio"
      [name]="'radio-btn-' + id + '-' + item.value"
      [id]="'radio-btn-' + id + '-' + item.value"
      [value]="item.value"
      (click)="onChange(item.value)"
    />
    <label
      [for]="'radio-btn-' + id + '-' + item.value"
      class="text-2022--dark btn {{ item.cssClass || !underLine ? 'btn-outline-2022--secondary' : '' }} d-flex align-items-center {{ large ? 'large-btn' : ''}}"
    >
      <img class="img-fluid" height="50" width="50" *ngIf="item.image" src="{{ item.image }}" [alt]="item.text" />
      <span *ngIf="item.text" class="ms-1">
        {{ item.text }}
      </span>
      <span *ngIf="item.template" class="ms-1">
        <ng-container *ngTemplateOutlet="item.template; context: item"></ng-container>
      </span>
    </label>
  </ng-container>
</div>
