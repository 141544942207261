<div
  class="input-group"
  [ngClass]="props.inputClass"
  [class.is-invalid]="showError"
  [class.disabled]="formControl.disabled"
>
  <span
    *ngIf="props.addonStart"
    class="input-group-text"
    [ngClass]="props.addonStartClass"
  >
    {{ props.addonStart }}
  </span>

  <input
    type="text"
    class="form-control"
    [formControl]="formControl"
    [placeholder]="props.placeholder"
    [formlyAttributes]="field"
    maxlength="11"
    mask="separator.0"
    thousandSeparator=","
    [attr.data-testid]="props.testId || field.key"
  />

  <span
    *ngIf="props.addonEnd"
    class="input-group-text"
    [ngClass]="props.addonEndClass"
  >
    {{ props.addonEnd }}
  </span>
</div>
