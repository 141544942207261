<div
  class="rounded-2 py-1"
  [ngClass]="'border border-2022--' + (showError ? 'danger' : 'grey')"
  [class.is-invalid]="showError"
>
  <div class="btn-group d-flex" role="group">
    <div
      *ngFor="let option of props.options; index as idx; first as first"
      class="col px-1"
      [class.border-start]="!first"
    >
      <input
        [id]="id + '_' + idx"
        type="radio"
        class="btn-check"
        [formControl]="formControl"
        [value]="option.value"
        [formlyAttributes]="field"
        [attr.data-testid]="(props.testId || field.key) + '_' + idx"
        [name]="key + id"
        [attr.name]="key + id"
      />
      <label
        class="btn w-100 btn-2022--secondary py-1"
        [class.btn-2022--white]="formControl.value !== option.value"
        [class.text-2022--danger-dark]="showError"
        [for]="id + '_' + idx"
      >
        {{ option.label }}
      </label>
    </div>
  </div>
</div>
