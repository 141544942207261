import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ActionType,
  PropertyMetadata,
  RulesEngineSchema,
  SetValueAction,
  TypeMetadata,
  validateSetValueAction,
} from '@msslib/models/rules-engine';
import { coerceType } from '../utils';

@Component({
  selector: 'lib-rules-engine-action-editor',
  templateUrl: 'action-editor.component.html',
  styleUrls: ['action-editor.component.scss'],
})
export class RulesEngineActonEditorComponent {
  @Input({ required: true }) public schema: RulesEngineSchema;

  @Input() public action: SetValueAction =
    { type: ActionType.SetValue, propertyName: '', value: undefined };
  @Output() public actionChange = new EventEmitter<SetValueAction>();

  @Input() public showErrors = false;

  public get properties() {
    return this.schema.properties.filter(p => p.isWriteable);
  }

  public get selectedPropertyMetadata(): PropertyMetadata | undefined {
    return this.schema.propertiesMap.get(this.action.propertyName);
  }

  public get selectedPropertyTypeMetadata(): TypeMetadata | undefined {
    const propertyMetadata = this.selectedPropertyMetadata;
    return propertyMetadata ? this.schema.types.get(propertyMetadata.typeName) : undefined;
  }

  public get errorMessage() {
    return validateSetValueAction(this.action, this.schema);
  }

  public setProperty(name: string) {
    const previousTypeName = this.selectedPropertyMetadata?.typeName;
    this.action.propertyName = name;
    const newTypeName = this.selectedPropertyMetadata?.typeName;

    // Try preserve value if the preview and new property types are the same
    this.setValue(previousTypeName === newTypeName ? this.action.value : null);
  }

  public setValue(value: any) {
    this.action.value = this.selectedPropertyMetadata && this.selectedPropertyTypeMetadata
      ? coerceType(value, this.selectedPropertyTypeMetadata, this.selectedPropertyMetadata.isNullable)
      : undefined;
    this.actionChange.emit(this.action);
  }

  public trackByName(_: number, { name }: { name: string }) {
    return name;
  }
}
