<div class="action-editor-grid">
  <span class="set center">Set</span>
  <lib-rules-engine-property-selector
    class="property"
    [properties]="properties"
    [propertyName]="action.propertyName"
    (propertyNameChange)="setProperty($event)" />

  <span class="to center">to</span>
  <lib-rules-engine-value-editor
    *ngIf="selectedPropertyMetadata"
    class="value"
    [type]="schema.types.get(selectedPropertyMetadata.typeName)"
    [nullable]="selectedPropertyMetadata.isNullable"
    [value]="action.value"
    (valueChange)="setValue($event)"
    label="Value" />
</div>
<p class="text-2022--danger m-0" *ngIf="showErrors && errorMessage">{{errorMessage}}</p>
