import { Injectable } from '@angular/core';
import { addDays, getCurrentDate, getDateString } from '@msslib/helpers/date-helpers';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ClubHubDataService} from '@msslib/services/clubhub-data.service';
import {
  Availability,
  LenderProductTransferViewModel,
  MatchedLenderProducts,
  MortgageType,
  Product,
  ProductTransferInfoModel,
} from 'apps/shared/src/models';

@Injectable({
  providedIn: 'root',
})
export class ProductsTransferService {
  public constructor(
    private dataService: ClubHubDataService,
  ) { }

  public getProductTransferDetails(lenderName: string): Observable<ProductTransferInfoModel> {
    return this.dataService.get<ProductTransferInfoModel>(`Product/TransferDetails/${lenderName}`);
  }

  public getProductTransferProducts(
    lendersProducts: MatchedLenderProducts[],
    currentMortgageLender: string,
    productTransferInitialDate: Date | string | undefined,
    mortgageType: MortgageType,
  ): Observable<LenderProductTransferViewModel | null> {

    if (!currentMortgageLender || mortgageType === MortgageType.Purchase) {
      return of(null);
    }
    const result: BehaviorSubject<LenderProductTransferViewModel | null> =
      new BehaviorSubject<LenderProductTransferViewModel | null>(null);
    this.getProductTransferDetails(currentMortgageLender).subscribe({
      next: (transferDetails: ProductTransferInfoModel) => {
        let productTransferProductsResult;

        if (!transferDetails?.timeBeforeApplicable) {
          productTransferProductsResult = {
            errorMessage: this.getNoEligibleItemsMessage(currentMortgageLender, null),
          } as LenderProductTransferViewModel;

          result.next(productTransferProductsResult);
          return;
        }

        const selectedLenderProducts = this.getSelectedLenderProducts(lendersProducts, currentMortgageLender);

        if (!selectedLenderProducts?.products) {
          productTransferProductsResult = {
            errorMessage: this.getNoEligibleItemsMessage(currentMortgageLender, transferDetails),
          } as LenderProductTransferViewModel;

          result.next(productTransferProductsResult);
          return;
        }

        const productTransferProducts = this.markAndGetProductTransferProducts(
          selectedLenderProducts.products,
          currentMortgageLender,
        );

        if (productTransferProducts.length < 1) {
          productTransferProductsResult = {
            errorMessage: this.getNoEligibleItemsMessage(currentMortgageLender, transferDetails),
          } as LenderProductTransferViewModel;

          result.next(productTransferProductsResult);
          return;
        }

        if (productTransferInitialDate) {
          productTransferInitialDate = new Date(productTransferInitialDate);

          const currentDate = getCurrentDate();
          if (productTransferInitialDate >= currentDate) {
            const availableDate = addDays(productTransferInitialDate, -transferDetails.timeBeforeApplicable);
            const isNotEligibleDate = availableDate > currentDate;
            if (isNotEligibleDate) {
              productTransferProductsResult = {
                errorMessage: this.getInvalidDateErrorMessage(currentMortgageLender, availableDate),
              } as LenderProductTransferViewModel;

              result.next(productTransferProductsResult);
              return;
            }
          }
        }

        productTransferProductsResult = {
          ...transferDetails,
          errorMessage: '',
          infoMessage: `Product Transfers available from ${currentMortgageLender}`,
          initialPeriodDate: productTransferInitialDate,
          lenderName: currentMortgageLender,
          products: productTransferProducts,
        };

        result.next(productTransferProductsResult);
      },
      error: () => {
        result.next({
          errorMessage: this.getNoEligibleItemsMessage(currentMortgageLender,null),
        } as LenderProductTransferViewModel);
        return;
      },
    });

    return result;
  }

  private markAndGetProductTransferProducts(products: Product[], selectedLender: string): Product[] {
    // Mark products as productTransfer
    const productTransferProducts = products
      .filter(p =>
        (p.productTransfer === Availability.AlsoAvailable || p.productTransfer === Availability.OnlyAvailable)
        && p.lender.toLowerCase() === selectedLender?.toLowerCase());

    /*
      Regarding to MCONE-5313 ticket we need to remove "ChapsFee", "ValuationFee"
      and "LegalFee" from productTransfer products
    */
    productTransferProducts.forEach(product => {
      // eslint-disable-next-line camelcase
      product.productTransferEligible = true;
      // eslint-disable-next-line camelcase
      product.isValuationFeeScaled = false;
      // eslint-disable-next-line camelcase
      product.freeLegal = null;
      // eslint-disable-next-line camelcase
      product.freeVal = null;
    });

    return productTransferProducts;
  }

  public filterProductTransferProducts(products: Product[], selectedLender?: string): Product[] {
    if (!selectedLender) {
      return products;
    }

    this.markAndGetProductTransferProducts(products, selectedLender);

    // Exclude ProductTransfer product of another lenders
    const productsWithoutProductTransfer = products
      .filter(p =>
        p.lender.toLowerCase() === selectedLender.toLowerCase()
        || p.lender.toLowerCase() !== selectedLender.toLowerCase() && p.productTransfer === Availability.No,
      );

    return productsWithoutProductTransfer;
  }

  private getSelectedLenderProducts(
    lendersProducts: MatchedLenderProducts[],
    currentMortgageLender: string,
  ): MatchedLenderProducts | undefined {
    return lendersProducts.find(r => r.lenderName.toLowerCase() === currentMortgageLender.toLocaleLowerCase());
  }

  private getNoEligibleItemsMessage(lenderName: string, transferDetails: ProductTransferInfoModel | null) {
    const noProductsMessage = `We don't currently have any product transfers available for ${lenderName}`;
    if (!transferDetails?.retentionUrl) {
      return noProductsMessage;
    }

    const link = `
      <a href="${transferDetails.retentionUrl}" target="_blank">
        You can visit this link to view their Product Transfer process
      </a>
    `;
    return `${noProductsMessage}. ${link}`;
  }

  private getInvalidDateErrorMessage(lenderName: string, maxAvailableDate: Date) {
    return 'Based on the information provided, your customer is not eligible for a Product Transfer with '
      + `<b>${lenderName}</b> until <b>${getDateString(maxAvailableDate)}</b>`;
  }
}
