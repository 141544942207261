import { EventEmitter, Inject, Injectable } from '@angular/core';
import { NgForm, UntypedFormGroup } from '@angular/forms';
import { FormlyFormOptions } from '@ngx-formly/core';
import {
  StepName,
} from 'apps/clubhub/src/app/ignite/models/affordability';
import {
  BtlType,
  LenderAuditResult,
  LenderProductTransferViewModel,
  LendingTypeCode,
  MatchedLenderProducts,
  MortgageType,
  OutcomeDetails,
  OutcomeResponse,
  Product,
  ProductTransferInfoModel,
  ProductType,
  PurchaserType,
  RepaymentMethod,
  SearchType,
} from 'apps/shared/src/models';
import { ResultsView } from 'apps/clubhub/src/app/ignite/models/affordability/enums';
import {
  AuthorizeService,
  ClubHubDataService,
  ConfigService,
  IgniteHelperService,
  LendingTypeService,
} from '@msslib/services';
import { roles } from '@msslib/constants/roles';
import {
  IBridging,
  IMortgageRequirements,
  IRepaymentMethod,
  IUiProductsSearchModel,
  ProductsBridgingModelRequest,
  ProductsBtlModelRequest,
  ProductsResidentialModelRequest,
} from 'apps/clubhub/src/app/ignite/models/products';
import { SimpleGridComponent } from '@msslib/components/simple-grid';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  of,
} from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { v4 as newGuid } from 'uuid';
import sortBy from 'lodash-es/sortBy';
import { ProductsFilterService } from './products-filter.service';
import { IgniteCommonDataService } from './ignite-common-data.service';
import {
  mockProductSearchRequest,
} from 'apps/clubhub/src/app/ignite/testing/affordability/mock-product-search-request';
import { ProductsTransferService } from 'apps/clubhub/src/app/ignite/services/products-transfer.service';
import {
  ProductsIntuitiveOutcomeService,
} from 'apps/clubhub/src/app/ignite/services/products-intuitive-outcome.service';
import { ProductsResultSettingsService } from 'apps/clubhub/src/app/ignite/services/products-result-settings.service';
import { ProductsCalculationService } from './products-calculation.service';
import { CustomProductCalculations, OutcomeResults } from '@msslib/models';
import { Router } from '@angular/router';
import { LoadingService } from '@msslib/services/loading.service';
import {
  CriteriaV2OutcomeService,
} from 'apps/clubhub/src/app/ignite/pages/criteria-v2/services/criteria-v2-outcome.service';

@Injectable({
  providedIn: 'root',
})
export class ProductsSearchService {

  public productsLoading = new BehaviorSubject(false);
  public allProductsLoading = new BehaviorSubject(false);
  public anyProductsLoading = combineLatest([this.productsLoading, this.allProductsLoading])
    .pipe(map(([a, b]) => a || b));

  public customProductCalculations: CustomProductCalculations;
  public customProductCalculationsData$: any = this.getCustomProductSettings();
  public form = new UntypedFormGroup({});
  public model: IUiProductsSearchModel;
  public options = {} as FormlyFormOptions;
  public currentStepName: StepName;
  public matchedProducts: MatchedLenderProducts[] | null;
  public matchedProductsFlat: Product[] | null;
  public productsAll$: Observable<Product[]> | null;
  public allProductsSnapshot = new BehaviorSubject<Product[] | null>(null);
  public matchedLenders: string[] = [];
  public productsModelRequest: ProductsResidentialModelRequest | ProductsBtlModelRequest
  | ProductsBridgingModelRequest | undefined = undefined;
  public lenderAuditResult: LenderAuditResult = LenderAuditResult.NotCompleted;
  public productsGrid: SimpleGridComponent;
  public resultsView: ResultsView = ResultsView.Details;
  public productsSearchId: string | undefined;
  public isAuditHistory: boolean;
  public requestHistory: unknown;
  public lenderList: string[] = [];
  public isOutcomeResults: boolean;
  public productReferencesToCompare = new Set<string>();
  public allProductsToCompare: Product[] = [];
  public hasPresetValue: boolean;
  public lenderProductTransferViewModel: LenderProductTransferViewModel | null;
  public productsSearchCompleted = new EventEmitter<void>();
  public productTransferModelSet = new EventEmitter<LenderProductTransferViewModel>();
  public productLoadingInProgress: boolean;
  private outcomeLoading = false;

  public constructor(
    @Inject(LendingTypeService) private lendingTypeService,
    @Inject(ClubHubDataService) private dataService: ClubHubDataService,
    private authService: AuthorizeService,
    private router: Router,
    private productsFilterService: ProductsFilterService,
    private igniteCommonDataService: IgniteCommonDataService,
    private igniteHelperService: IgniteHelperService,
    private productsTransferService: ProductsTransferService,
    private configService: ConfigService,
    private productsIntuitiveOutcomeService: ProductsIntuitiveOutcomeService,
    private productsCalculationService: ProductsCalculationService,
    private loadingScreenService: LoadingService,
    private productsResultSettingsService: ProductsResultSettingsService,
    private criteriaV2OutcomeService: CriteriaV2OutcomeService,
  ) {
    this.reset();
    this.criteriaV2OutcomeService.outcomeUpdated.subscribe(() => {
      this.matchedProductsFlat = this.getLendersProductsFlat();
    });
  }

  public getProductsResidentialFormValue(): IUiProductsSearchModel {
    return this.form.value;
  }

  public reset(model: IUiProductsSearchModel | null = null) {
    if (!this.authService.historyView && !this.router.url.includes('outcomeHistory')) {
      this.customProductCalculationsData$
        .subscribe(customProductSettings => this.customProductCalculations = customProductSettings);
    }
    this.form.markAsUntouched();
    this.form = new UntypedFormGroup({});
    this.model = model ?? this.initializeModel();
    this.currentStepName = StepName.MortgageRequirements;
    if (this.options.resetModel) {
      this.options.resetModel();
    }
    this.productsFilterService.clearFilters();
    this.productsFilterService.clearSourcingFilters();
    this.productReferencesToCompare.clear();
    this.options = {};

    this.igniteCommonDataService.productModel = this.model;

    if (!!model) {
      this.model = model;
      this.form.reset(this.model);
    }
  }

  private getCustomProductSettings(): Observable<CustomProductCalculations> {
    return this.dataService.get<CustomProductCalculations>('CustomProductSettings/Users');
  }

  public resetProductTransfer(): void {
    this.lenderProductTransferViewModel = null;
  }

  public resetAllProducts(): void {
    this.productsAll$ = null;
  }

  public isComingSoonLender(lender: string): boolean {
    return ['BM Solutions'].includes(lender);
  }

  public resetParamsToDefault(): void {
    this.productsResultSettingsService.resetSort();
    this.igniteCommonDataService.productModel = null;
    this.igniteCommonDataService.propertyModel = null;
    this.productsSearchId = undefined;
    this.productsIntuitiveCriteriaId = null;
    this.matchedProducts = [];
    this.matchedProductsFlat = [];
    this.allProductsSnapshot = new BehaviorSubject<Product[] | null>(null);
    this.matchedLenders = [];
    this.productsModelRequest = undefined;
    this.lenderAuditResult = LenderAuditResult.NotCompleted;
    this.resultsView = ResultsView.Details;
    this.isAuditHistory = false;
    this.lenderList = [];
    this.productReferencesToCompare = new Set<string>();
    this.allProductsToCompare = [];
    this.resetProductTransfer();
  }

  public get isSecondChargeProducts(): boolean {
    return this.productsModelRequest?.mortgageType === MortgageType.SecondCharge;
  }

  public get productsToCompare(): (Product | Observable<Product[]>)[] {
    const matchedProducts = this.matchedProductsFlat;
    const allProducts = this.allProducts$;

    const prods = [...this.productReferencesToCompare].map(ref => {
      // Favour fetching the Product from the matched products first as this will have more accurate fee information
      // If not found AND the user has fetched all products, fallback to find the product in here (no need to check here
      // if the user has not fetched all products, since in that case they can only have selected a matched product)
      return matchedProducts?.find(x => x.uniqueProductReference === ref)
        ?? allProducts?.pipe(
          tap((products: Product[]) => products.find(x => x.uniqueProductReference === ref)))
        ?? this.allProductsToCompare?.find(x => x.uniqueProductReference === ref);
    });
    return prods;
  }

  public toggleProductComparison(product: Product): void {
    const ref = product.uniqueProductReference;
    if (this.productReferencesToCompare.has(ref)) {
      this.productReferencesToCompare.delete(ref);
      this.allProductsToCompare = this.allProductsToCompare
        .filter(product => product.uniqueProductReference !== ref);
    } else {
      this.productReferencesToCompare.add(ref);
      this.allProductsToCompare = [...this.allProductsToCompare, product];
    }
  }

  public isProductBeingCompared(product: Product): boolean {
    return this.productReferencesToCompare.has(product.uniqueProductReference);
  }

  public startLoadOutcomeHistory() {
    this.outcomeLoading = true;
    this.loadingScreenService.startLoading({ loading: true });
  }

  public stopLoadOutcomeHistory() {
    this.outcomeLoading = false;
    this.loadingScreenService.stopLoading({ loading: false });
  }

  public get outcomeHistoryLoading(): boolean {
    return this.outcomeLoading;
  }

  public get isBtlLendingType(): boolean {
    return this.productTypeCode?.toLowerCase() === 'btl';
  }

  public get isResLendingType(): boolean {
    return this.productTypeCode?.toLowerCase() === 'res';
  }

  public get productTypeCode(): string {
    return this.lendingTypeService.lendingType?.code;
  }

  public get productTypeString(): string {
    return this.lendingTypeService.lendingType.name;
  }

  public get productTypeId(): number {
    return this.lendingTypeService.lendingType.id;
  }

  public get isLender() {
    return this.authService.hasRole(roles.lender);
  }

  public get isDetailsView() {
    return this.resultsView === ResultsView.Details;
  }

  public get isInputView() {
    return this.resultsView === ResultsView.Input;
  }

  public get isViewAllProductsView() {
    return this.resultsView === ResultsView.ViewAllProducts;
  }

  public get isComparedProductsView() {
    return this.resultsView === ResultsView.ComparedProducts;
  }

  public get hasComparedProducts(): boolean {
    return this.productReferencesToCompare.size > 0;
  }

  public get allProducts$() {
    return this.productsAll$ ?? (this.productsAll$ = this.getAllProducts());
  }

  public get comparedProducts(): (Product | undefined)[] {
    return [...this.productReferencesToCompare].map(ref =>
      this.matchedProductsFlat?.find(prod => prod.uniqueProductReference === ref) ??
      this.allProductsSnapshot.value?.find(prod => prod.uniqueProductReference === ref));
  }

  public get mock() {
    return this.configService.isLocal && false;
  }

  public get productsOnly(): boolean {
    return this.igniteCommonDataService.productsOnly;
  }

  public get propertyValueDisabled(): boolean {
    return this.hasPresetValue && this.productsOnly;
  }

  public get loanAmountDisabled(): boolean {
    return this.hasPresetValue && this.productsOnly;
  }

  public get hasProducts(): boolean {
    return !!this.matchedProductsFlat?.length;
  }

  private initializeModel(): IUiProductsSearchModel {
    this.hasPresetValue = false;
    let propertyValue: number | null = null;
    let loanAmount: number | null = null;

    const propertyModel = this.igniteCommonDataService.propertyModel;
    if (this.productsOnly && propertyModel) {
      propertyValue = propertyModel.propertyDetails?.propertyValue ?? null;
      loanAmount = propertyModel.propertyDetails?.loanAmount ?? null;

      this.hasPresetValue = !!propertyValue && !!loanAmount;
    }

    return this.mock ? mockProductSearchRequest : {
      mortgageRequirements: {
        propertyValue,
        loanAmount,
        mortgageLender: {
          value: null,
        },
        mortgageType: null,
        productTypeExtended: ProductType.Standard,
        newBuild: false,
        remortgageType: null,
        purchaserType: null,
        helpToBuy: null,
        repaymentMethod: {
          value: null,
          showInterestOnlyCriteria: null,
          showInterestOnlyPartAndPartCriteria: null,
        },
        interestOnlyAmount: null,
        mortgageTerm: {
          years: null,
          months: null,
        },
        btlType: this.lendingTypeService.isResLendingType ? null : BtlType.Standard,
        limitedCompanyPurchase: false,
        portfolioLandlord: false,
      },
    } as any;
  }

  public get criteriaLoadingInProgress(): boolean {
    return this.productsIntuitiveOutcomeService.criteriaLoadingInProgress;
  }

  public get productsIntuitiveCriteriaId(): string | null {
    return this.productsIntuitiveOutcomeService.criteriaSearchId;
  }

  public set productsIntuitiveCriteriaId(value: string | null) {
    this.productsIntuitiveOutcomeService.criteriaSearchId = value;
  }

  public getProducts(
    scheduled: boolean,
    fromAffordabilityProductsModel?: ProductsResidentialModelRequest | ProductsBtlModelRequest,
  ): Observable<MatchedLenderProducts[]> {
    return this.dataService.post<MatchedLenderProducts[]>(
      `Product/?scheduled=${scheduled}`, this.productsModelRequest,
    ).pipe(
      map(results => {
        return results.map(lenderProduct => ({
          ...lenderProduct,
          get trueCostInitialPeriodFrom() {
            const trueCostInitialPeriods = lenderProduct.products
              ?.map(product => product.trueCostInitialPeriod)
              .filter(x => !!x) as number[];
            return trueCostInitialPeriods?.length
              ? Math.min(...trueCostInitialPeriods)
              : null;
          },
          get monthlyPaymentFrom() {
            const initialMonthlyPayment = lenderProduct.products
              ?.map(product => product.initialMonthlyPayment)
              .filter(x => !!x) as number[];
            return initialMonthlyPayment?.length
              ? Math.min(...initialMonthlyPayment)
              : null;
          },
        }));
      }),
      tap(
        (results) => {
          for (const lenderProduct of results) {
            if (lenderProduct.products) {
              this.productsCalculationService.performCalculations(
                lenderProduct.products,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                fromAffordabilityProductsModel ?? this.productsModelRequest!,
                this.customProductCalculations.deductCashback,
                this.customProductCalculations.customFeeCalculationScenarios,
                this.customProductCalculations.calculateInitialTrueCostOverMonths,
                this.customProductCalculations.customFees,
                this.customProductCalculations.assumedLegalFeeCost,
              );
            }
            for (const product of lenderProduct.products ?? []) {
              product.lender = lenderProduct.lenderName;
              // eslint-disable-next-line camelcase
              product.lenderLogoUrl = lenderProduct.lenderLogoUrl;
            }
          }
          if (this.productsModelRequest) {
            const currentMortgageLender = this.productsModelRequest.currentMortgageLender || '';
            if (this.productsModelRequest.productType === LendingTypeCode.Bdg) {
              this.prepareMatchedProductResults(results, currentMortgageLender);
              this.productsSearchCompleted.emit();
            } else {
              this.setProductTransferProducts(
                results,
                this.productsModelRequest?.currentMortgageLender ?? '',
                this.productsModelRequest?.productTransferInitialDate,
                this.productsModelRequest?.mortgageType,
              ).subscribe(() => {
                this.prepareMatchedProductResults(results, currentMortgageLender);
                this.productsSearchCompleted.emit();
              });
            }
          } else {
            this.productsSearchCompleted.emit();
          }
        },
      ),
    );
  }

  public getProductsAndCriteria(
    scheduled: boolean,
    fromAffordabilityProductsModel?: ProductsResidentialModelRequest | ProductsBtlModelRequest
      | ProductsBridgingModelRequest,
  ): Observable<[MatchedLenderProducts[], [OutcomeResponse[], OutcomeDetails]]> {
    this.handleProductsModel(fromAffordabilityProductsModel);
    this.productsLoading.next(true);
    this.productLoadingInProgress = true;

    const getIntuitiveOutcomeObservable = this.shouldLoadIntuitiveCriteria
      ? this.productsIntuitiveOutcomeService
        .getIntuitiveOutcome(this.productsModelRequest as ProductsResidentialModelRequest | ProductsBtlModelRequest)
      : of(null);


    const getProductsObservable = this.getProducts(scheduled, fromAffordabilityProductsModel);

    const resultsObservable = combineLatest([getProductsObservable, getIntuitiveOutcomeObservable]);

    this.igniteCommonDataService.searchToolsInUse.products = true;

    if (this.shouldLoadIntuitiveCriteria) {
      this.igniteCommonDataService.searchToolsInUse.criteria = true;
    }

    return resultsObservable;
  }

  private getLendersProductsFlat(): Product[] {
    if (!this.matchedProducts) {
      return [];
    }

    this.matchedProducts.forEach(lenderProducts => {
      const outcomeResult = this.criteriaV2OutcomeService.getLenderOverallResultByLender(lenderProducts.lenderName);
      if (outcomeResult) {
        lenderProducts.outcomeResult = outcomeResult;

        // Exclude products with NO result
        if (outcomeResult === OutcomeResults.No) {
          lenderProducts.products = [];
        } else {
          lenderProducts.products?.forEach(p => p.lenderOutcomeResult = outcomeResult);
        }
      }
    });

    return this.matchedProducts.flatMap(p => p.products ?? []);
  }

  private prepareMatchedProductResults(results: MatchedLenderProducts[], currentMortgageLender: string): void  {
    results.forEach(lender => {
      if (lender.lenderName === 'Leeds') {
        lender.products?.forEach(product => {
          if (product.initialMonthlyPayment === null) {
            // Leeds return aprc but we don't want to show this unless standard calcs are populated
            product.aprc = null;
          }
        });
      }
    });
    this.matchedLenders = sortBy([...new Set(results.filter(lender => lender.products).map(product => {
      return product.lenderName;
    }))]);

    this.matchedProducts = results;
    this.matchedProductsFlat = this.getLendersProductsFlat();

    this.matchedProductsFlat = this.excludeProductTransferProducts(this.matchedProductsFlat, currentMortgageLender);

    this.productsLoading.next(false);
    this.productLoadingInProgress = false;
  }

  public excludeProductTransferProducts(products: Product[], currentMortgageLender: string): Product[] {
    // Mark all productTransfer products and exclude from matchedProductsFlat
    return this.productsTransferService.filterProductTransferProducts(products, currentMortgageLender);
  }

  public setProductTransferProducts(
    lendersProducts: MatchedLenderProducts[],
    currentMortgageLender: string,
    productTransferInitialDate: Date | string | undefined,
    mortgageType: MortgageType,
  ): Observable<LenderProductTransferViewModel> {
    return this.productsTransferService
      .getProductTransferProducts(
        lendersProducts,
        currentMortgageLender,
        productTransferInitialDate,
        mortgageType,
      )
      .pipe(tap((result: LenderProductTransferViewModel) => {
        this.lenderProductTransferViewModel = result;
        this.productTransferModelSet.emit(result);
      }));
  }

  private handleProductsModel(
    fromAffordabilityProductsModel?: ProductsResidentialModelRequest | ProductsBtlModelRequest,
  ): void {
    if (fromAffordabilityProductsModel && this.model.mortgageRequirements.mortgageTerm) {
      Object.assign(this.model.mortgageRequirements, fromAffordabilityProductsModel);
      this.model.mortgageRequirements.mortgageTerm.months = fromAffordabilityProductsModel.mortgageTermMonths;
      this.model.mortgageRequirements.mortgageTerm.years = fromAffordabilityProductsModel.mortgageTermYears;
    }
    this.productsSearchId = newGuid();
    this.productsModelRequest = this.mappedModel(
      (this.form.valid && !this.form.pristine) ? this.form.value : this.model,
    );

    if (this.productsModelRequest) {
      this.productsFilterService.productsFilterContext = this.productsModelRequest;
      this.productsFilterService.setProductFilterDefinitions();
      this.productsModelRequest.repaymentMethod =
        this.adjustRepaymentMethod(this.productsModelRequest.repaymentMethod);
    }
  }

  public adjustRepaymentMethod(repaymentMethod: number | IRepaymentMethod | undefined): number | undefined {
    // this is due to mapping from affordability to product
    return (typeof repaymentMethod === 'number') ? repaymentMethod : (repaymentMethod)?.value;
  }

  public updateModel(model = {}, form?: NgForm) {
    this.model = { ...this.model, ...model };
    this.productsModelRequest = this.mappedModel(this.model);
    const formValues = form?.form?.getRawValue();

    if (formValues && !!this.form.value.bridging) {
      this.form.value.bridging.loanAmount = formValues.loanAmount;
      this.form.value.bridging.propertyValue = formValues.propertyValue;
      this.form.value.bridging.loanTermMonths = formValues.months;
      this.form.value.bridging.methodOfRepayment = formValues.repaymentMethod;
    }

    if (formValues && !!this.form.value.mortgageRequirements) {
      this.form.value.mortgageRequirements.loanAmount = formValues.loanAmount;
      this.form.value.mortgageRequirements.years = formValues.years;
      this.form.value.mortgageRequirements.months = formValues.months;
      this.form.value.mortgageRequirements.mortgageTerm.years = formValues.years;
      this.form.value.mortgageRequirements.mortgageTerm.months = formValues.months;
      this.form.value.mortgageRequirements.propertyValue = formValues.propertyValue;
      this.form.value.mortgageRequirements.repaymentMethod.value = formValues.repaymentMethod;
      if (formValues.repaymentMethod?.value === RepaymentMethod.InterestOnlyPartAndPart) {
        this.form.value.mortgageRequirements.interestOnlyAmount = formValues.interestOnlyAmount;
      }
    }
  }

  public updateModelFromSF(model: ProductsResidentialModelRequest | ProductsBtlModelRequest) {
    if (this.model.mortgageRequirements) {
      this.model.mortgageRequirements.loanAmount = model.loanAmount;
      if (this.model.mortgageRequirements.mortgageTerm) {
        this.model.mortgageRequirements.mortgageTerm.years = model.mortgageTermYears;
        this.model.mortgageRequirements.mortgageTerm.months = model.mortgageTermMonths;
      }
      this.model.mortgageRequirements.propertyValue = model.propertyValue;
      this.model.mortgageRequirements.mortgageType = model.mortgageType;
      this.model.mortgageRequirements.helpToBuy = (model as ProductsResidentialModelRequest).helpToBuy;
      this.model.mortgageRequirements.purchaserType = model.purchaserType;
      this.model.mortgageRequirements.repaymentMethod = {
        value: model.repaymentMethod,
        showInterestOnlyCriteria: false,
        showInterestOnlyPartAndPartCriteria: false,
      };
      if (model.repaymentMethod === RepaymentMethod.InterestOnlyPartAndPart) {
        this.model.mortgageRequirements.interestOnlyAmount = model.interestOnlyAmount;
      }
      if (model.mortgageType === MortgageType.Remortgage) {
        this.model.mortgageRequirements.currentMortgageLender = model.currentMortgageLender;
        if (model.productTransferInitialDate) {
          const productTransferInitialDate = this.igniteHelperService
            .getProductTransferInitialDate(model.productTransferInitialDate);
          if (productTransferInitialDate) {
            this.model.mortgageRequirements.productTransferInitialDate = productTransferInitialDate;
          }
        }
      }
    }
  }

  public reloadAllProducts(): void {
    this.productsAll$ = this.getAllProducts();
  }

  private getAllProducts(): Observable<Product[]> {
    this.productsSearchId = newGuid();
    this.productsModelRequest = this.mappedModel(
      (this.form.valid && !this.form.pristine) ? this.form.value : this.model,
    );
    this.allProductsLoading.next(true);
    return this.dataService.post<Product[]>
    (`product/GetAllProducts/${this.productsModelRequest?.productType === LendingTypeCode.Bdg
      ? 'Bridging' : 'ResiBtl'}`, this.productsModelRequest)
      .pipe(
        tap(() => this.allProductsLoading.next(false)),
        map(products => {
          if (this.lenderProductTransferViewModel) {
            return this.productsTransferService
              .filterProductTransferProducts(products, this.productsModelRequest?.currentMortgageLender);
          }

          return products;
        }),
        tap(products => {
          this.productsCalculationService.performCalculations(
            products,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.productsModelRequest!,
            this.customProductCalculations.deductCashback,
            this.customProductCalculations.customFeeCalculationScenarios,
            this.customProductCalculations.calculateInitialTrueCostOverMonths,
            this.customProductCalculations.customFees,
            this.customProductCalculations.assumedLegalFeeCost,
          );

          this.allProductsSnapshot.next(products);
        }),
      );
  }

  public getLoanAmount(): number | undefined {
    return this.productsModelRequest?.loanAmount;
  }

  public getMortgageTerm(): string | undefined {
    const mortgageTerm: number | undefined = this.productsModelRequest?.mortgageTermYears;
    if (mortgageTerm) {
      const length: string = (mortgageTerm > 1) ? 'years' : 'year';
      return `${ mortgageTerm } ${ length }`;
    }
  }

  public getRepaymentMethod(): string | undefined {
    switch (this.productsModelRequest?.repaymentMethod) {
      case RepaymentMethod.CapitalAndInterest:
        return 'Capital and Interest';
      case RepaymentMethod.InterestOnly:
        return 'Interest Only';
      case RepaymentMethod.InterestOnlyPartAndPart:
        return 'Interest Only Part and Part';
    }
  }

  public resetProducts(): void {
    this.resetAllProducts();
    this.allProductsSnapshot.next(null);
    this.matchedProducts = null;
    this.matchedProductsFlat = null;
    this.productReferencesToCompare.clear();
    this.isOutcomeResults = false;
    this.resetProductTransfer();
  }

  private mappedModel(uiModel: IUiProductsSearchModel) {
    switch (this.productTypeCode.toUpperCase()) {
      case LendingTypeCode.Res:
        return this.mappedModelResi(uiModel);
      case LendingTypeCode.Btl:
        return this.mappedModelBtl(uiModel);
      case LendingTypeCode.Bdg:
        return this.mappedModelBdg(uiModel);
      default:
        return;
    }
  }

  private mappedModelBdg(uiModel: IUiProductsSearchModel): ProductsBridgingModelRequest {
    const {
      bridging: bridging,
    } = uiModel;
    return {
      productsSearchId: this.productsSearchId,
      productType: LendingTypeCode.Bdg,
      regulated: bridging.regulated,
      methodOfRepayment: bridging.methodOfRepayment,
      propertyValue: bridging.propertyValue,
      limitedCompany: bridging.limitedCompany,
      currentMortgageBalanceOutstanding: bridging.currentMortgageBalanceOutstanding,
      loanSecurity: bridging.loanSecurity,
      loanAmount: bridging.loanAmount,
      loanTerm: bridging.loanTermMonths,
    } as ProductsBridgingModelRequest;
  }

  private mappedModelResi(uiModel: IUiProductsSearchModel): ProductsResidentialModelRequest {
    const {
      mortgageRequirements: mortgageRequirements,
    } = uiModel;
    return {
      productsSearchId: this.productsSearchId,
      productType: LendingTypeCode.Res,
      productTypeExtended: mortgageRequirements.productTypeExtended,
      newBuild: mortgageRequirements.newBuild,
      currentMortgageLender: this.mapCurrentMortgageLender(uiModel.mortgageRequirements),
      productTransferInitialDate: this.igniteHelperService
        .getDateOrNull(uiModel.mortgageRequirements.productTransferInitialDate),
      mortgageType: mortgageRequirements.mortgageType,
      remortgageType: mortgageRequirements.remortgageType,
      propertyValue: mortgageRequirements.propertyValue,
      loanAmount: mortgageRequirements.loanAmount,
      outstandingBalanceCurrentMortgage: mortgageRequirements.outstandingBalanceCurrentMortgage,
      helpToBuy: mortgageRequirements.helpToBuy,
      repaymentMethod: this.adjustRepaymentMethod(mortgageRequirements.repaymentMethod),
      mortgageTermYears: mortgageRequirements.mortgageTerm?.years,
      mortgageTermMonths: mortgageRequirements.mortgageTerm?.months ?? 0,
      interestOnlyAmount: mortgageRequirements.interestOnlyAmount,
      searchType: SearchType.Product,
      firstTimeBuyer: mortgageRequirements.purchaserType ?
        mortgageRequirements.purchaserType === PurchaserType.FirstTimeBuyer : null,
    } as ProductsResidentialModelRequest;
  }

  private mappedModelBtl(uiModel: IUiProductsSearchModel): ProductsBtlModelRequest {
    const {
      mortgageRequirements: mortgageRequirements,
    } = uiModel;

    return {
      productsSearchId: this.productsSearchId,
      productType: LendingTypeCode.Btl,
      newBuild: mortgageRequirements.newBuild,
      mortgageType: mortgageRequirements.mortgageType,
      currentMortgageLender: this.mapCurrentMortgageLender(uiModel.mortgageRequirements),
      productTransferInitialDate: this.igniteHelperService
        .getDateOrNull(uiModel.mortgageRequirements.productTransferInitialDate),
      remortgageType: mortgageRequirements.remortgageType,
      propertyValue: mortgageRequirements.propertyValue,
      loanAmount: mortgageRequirements.loanAmount,
      outstandingBalanceCurrentMortgage: mortgageRequirements.outstandingBalanceCurrentMortgage,
      purchaserType: mortgageRequirements.purchaserType,
      repaymentMethod: this.adjustRepaymentMethod(mortgageRequirements.repaymentMethod),
      mortgageTermYears: mortgageRequirements.mortgageTerm?.years,
      mortgageTermMonths: mortgageRequirements.mortgageTerm?.months ?? 0,
      btlType: mortgageRequirements.btlType,
      limitedCompanyPurchase: mortgageRequirements.limitedCompanyPurchase,
      portfolioLandlord: mortgageRequirements.portfolioLandlord,
      interestOnlyAmount: mortgageRequirements.interestOnlyAmount,
      searchType: SearchType.Product,
      // Default value for BTL
      productTypeExtended: ProductType.Standard,
    } as ProductsBtlModelRequest;
  }

  public set auditResultsStatusChange(status: LenderAuditResult) {
    this.lenderAuditResult = status;
  }

  public getPreviousOutcome(productsSearchId: string) {
    return this.dataService.get<any>(`Product/outcome/${productsSearchId}`);
  }

  public getProductTransferDetails(lenderName: string): Observable<ProductTransferInfoModel> {
    return this.productsTransferService.getProductTransferDetails(lenderName);
  }

  private mapCurrentMortgageLender(model: IMortgageRequirements) {
    return this.igniteHelperService
      .getCurrentMortgageLender(model.currentMortgageLender, model.mortgageLender);
  }

  public processHistoricProducts(result) {
    this.startLoadOutcomeHistory();
    const request = JSON.parse(result.requestJson);

    if (request) {
      // MCONE-4953: Force set NULL to prevent zero value. (loanAmount and propertyValue can't be Zero)
      request.loanAmount = request.loanAmount ?? null;
      request.propertyValue = request.propertyValue ?? null;
    }

    const response = JSON.parse(result.responseJson);
    if (response.products) {
      this.matchedProducts = response.products;
    } else if (result.responseJson) {
      const products = JSON.parse(result.responseJson);
      this.matchedProducts = products;
    }

    if (this.matchedProducts) {
      for (const lenderProduct of this.matchedProducts) {
        if (lenderProduct.products) {
          this.productsCalculationService.performCalculations(
            lenderProduct.products,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            request,
            this.customProductCalculations.deductCashback,
            this.customProductCalculations.customFeeCalculationScenarios,
            this.customProductCalculations.calculateInitialTrueCostOverMonths,
            this.customProductCalculations.customFees,
            this.customProductCalculations.assumedLegalFeeCost,
          );
        }
        for (const product of lenderProduct.products ?? []) {
          product.lender = lenderProduct.lenderName;
          // eslint-disable-next-line camelcase
          product.lenderLogoUrl = lenderProduct.lenderLogoUrl;
        }
      }
      this.matchedProducts = this.matchedProducts.map(lenderProduct => ({
        ...lenderProduct,
        get trueCostInitialPeriodFrom() {
          const trueCostInitialPeriods = lenderProduct.products
            ?.map(product => product.trueCostInitialPeriod)
            .filter(x => !!x) as number[];
          return trueCostInitialPeriods?.length
            ? Math.min(...trueCostInitialPeriods)
            : null;
        },
        get monthlyPaymentFrom() {
          const initialMonthlyPayment = lenderProduct.products
            ?.map(product => product.initialMonthlyPayment)
            .filter(x => !!x) as number[];
          return initialMonthlyPayment?.length
            ? Math.min(...initialMonthlyPayment)
            : null;
        },
      }));

      this.matchedProductsFlat = this.matchedProducts.flatMap(p => p.products?.map(x => ({
        ...x,
        lender: p.lenderName,
        lenderLogoUrl: p.lenderLogoUrl,
      })) ?? []);
    }

    if (this.matchedProductsFlat) {
      this.matchedProductsFlat = this.excludeProductTransferProducts(
        this.matchedProductsFlat,
        request.currentMortgageLender,
      );
    }

    const mortgageRequirements = {
      mortgageLender: !!request.currentMortgageLender
        ? { value: request.currentMortgageLender, label: request.currentMortgageLender }
        : {},
      currentMortgageLender: request.currentMortgageLender,
      productTransferInitialDate: this.igniteHelperService
        .getProductTransferInitialDate(request.productTransferInitialDate),
      currentMortgageBalance: request.currentMortgageBalance,
      mortgageType: request.mortgageType,
      productTypeExtended: request.productTypeExtended,
      btlType:request.btlType,
      helpToBuy:request.helpToBuy,
      limitedCompanyPurchase: request.limitedCompanyPurchase,
      loanAmount: request.loanAmount,
      mortgageTerm: {months: request.mortgageTermMonths, years: request.mortgageTermYears },
      portfolioLandlord: request.portfolioLandlord,
      productType: request.productType,
      propertyValue: request.propertyValue,
      purchaserType: request.purchaserType,
      remortgageType: request.remortgageType,
      repaymentMethod: { value: request.repaymentMethod } as IRepaymentMethod,
      newBuild: request.newBuild ?? false,
    } as IUiProductsSearchModel['mortgageRequirements'];

    const bridging = {
      regulated: request.regulated,
      propertyValue: request.propertyValue,
      loanAmount: request.loanAmount,
      loanSecurity: request.loanSecurity,
      currentMortgageBalanceOutstanding: request.currentMortgageBalanceOutstanding,
      loanTermMonths: request.loanTerm,
      methodOfRepayment: request.methodOfRepayment,
      limitedCompany: request.limitedCompany,
    } as IBridging;

    this.setProductTransferProducts(
      this.matchedProducts ?? [],
      mortgageRequirements.currentMortgageLender ?? '',
      request.productTransferInitialDate,
      request.mortgageType,
    ).subscribe(() => {
      this.requestHistory = mortgageRequirements;
      this.reset({ mortgageRequirements, bridging } as IUiProductsSearchModel);
      this.productsModelRequest = request;
      this.isOutcomeResults = true;
      this.resultsView = ResultsView.MatchedProducts;
      this.currentStepName = StepName.Results;
      this.productsLoading.next(false);
    });

    this.resultsView = ResultsView.MatchedProducts;
    this.stopLoadOutcomeHistory();
  }

  public loadSavedProducts(productsSearchId: string | undefined): Observable<unknown> {
    if (!productsSearchId) {
      return of();
    }

    this.productsSearchId = productsSearchId;

    this.currentStepName = StepName.Results;
    this.resultsView = ResultsView.MatchedProducts;
    this.productsLoading.next(true);

    return this.getPreviousOutcome(productsSearchId).pipe(
      tap(result => {
        this.lendingTypeService.lendingType =
          this.lendingTypeService.lendingTypes.find((l) => l.id === result.lendingTypeId);
        this.processHistoricProducts(result);
      }),
    );
  }

  public get productTransferMessage(): string {
    if (this.lenderProductTransferViewModel) {
      return this.lenderProductTransferViewModel.infoMessage ?? this.lenderProductTransferViewModel.errorMessage;
    }

    return '';
  }

  public get hasProductTransfer(): boolean {
    return !!this.lenderProductTransferViewModel;
  }

  public get shouldLoadIntuitiveCriteria(): boolean {
    return (this.lendingTypeService.isResLendingType || this.lendingTypeService.isBtlLendingType);
  }
}
