/* eslint-disable indent */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
} from '@angular/forms';
import { FormsValidators } from '@msslib/components/forms/validators';
import { FloorRateViewModel } from '@msslib/models/floor-rate';
import { Lender, RateType } from 'apps/shared/src/models';
import { FloorRatesService } from '@msslib/services/floor-rates.service';
import { ToastService } from '@msslib/services/toast.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'lib-floor-rates',
  templateUrl: 'floor-rates.component.html',
  styleUrls: ['floor-rates.component.scss'],
})
export class FloorRatesComponent implements OnInit, OnDestroy {
  @Input() public lender: Lender;

  /*
    Use to display all floor rates for all lender of selected lenderName
    Actual for lenderHub app
  */
  @Input() public lenderNameId: number | undefined;

  /*
    Use that list to prevent displaying of unneeded lending types.
    Actual for lenderHub app
    For exapmle ['bridging']
  */
  @Input() public excludeLendingTypes: string[] = ['bridging', 'second charge'];

  public floorRates: FloorRateViewModel[];
  public floorRateForms: UntypedFormGroup[] = [];
  private rateTypes: { rateType: RateType; name: string }[] = [
    { rateType: RateType.Tracker, name: 'Tracker' },
    { rateType: RateType.Variable, name: 'Variable' },
    { rateType: RateType.Discount, name: 'Discount' },
];

  // Subject used to unsubscribe from all form observables (valueChanges)
  private formUnsubscribe = new Subject<void>();

  @Output() public unsavedChangesChange = new EventEmitter<boolean>();

  public constructor(
    private fb: UntypedFormBuilder,
    private floorRatesService: FloorRatesService,
    private toastService: ToastService,
  ) {}

  public ngOnInit(): void {
    this.refreshFloorRates();
  }

  private refreshFloorRates(): void {
    if (this.lenderNameId) {
      this.getFloorRatesByLenderNameId(this.lenderNameId);
    } else if (!!this.lender) {
      this.getLenderFloorRates(this.lender.id);
    }
  }

  public submit() {
    if (this.floorRateForms.some((f: FormGroup) => f.invalid)) {
      this.floorRateForms.forEach((f: FormGroup) =>{
        f.markAllAsTouched();
        f.updateValueAndValidity();
      });
      return;
    }
    const floorRates = this.floorRateForms.map(form => form.value);
    floorRates.forEach(form => {
      form.rateTypes = form.rateTypes?.filter(type => type !== false);
    });
    this.floorRatesService.updateFloorRates(floorRates).subscribe(() => {
      this.toastService.success('Floor Rates successfully updated');
      this.unsavedChangesChange.emit(false);
      this.refreshFloorRates();
    });
  }

  public trackByFn(index: number) {
    return index;
  }

  public onSwitch(value: boolean, fieldName: string, floorRateForm: UntypedFormGroup) {
    floorRateForm.get(fieldName)?.setValue(value);

    // Only one toggle can be turned ON
    if (value) {
      const switchOffFieldName = fieldName === 'fixedFloorRateEnabled'
        ? 'floorRateBaseEnabled'
        : 'fixedFloorRateEnabled';
      floorRateForm.get(switchOffFieldName)?.setValue(false);
    }
    if (!floorRateForm.get('fixedFloorRateEnabled')?.value && !floorRateForm.get('floorRateBaseEnabled')?.value) {
      floorRateForm.get('rateTypes')?.reset();
      floorRateForm.get('rateTypes')?.disable();
    } else {
      floorRateForm.get('rateTypes')?.enable();
    }
  }

  public isFieldInvalid(fieldName: string, floorRateForm: UntypedFormGroup) {
    const control = floorRateForm.controls[fieldName];
    return (control?.touched || control?.dirty) && !control?.valid;
  }

  public get isFormsValid(): boolean {
    return this.floorRateForms.every(f => f.valid);
  }

  public get saveButtonEnabled(): boolean {
    return this.floorRateForms?.length > 0;
  }

  private getFloorRatesByLenderNameId(lenderNameId: number) {
    this.floorRatesService.getFloorRatesByLenderNameId(lenderNameId).subscribe((floorRates) => {
      this.prepareFloorRateForms(floorRates);
    });
  }

  private getLenderFloorRates(lenderId: number) {
    this.floorRatesService.getFloorRatesByLenderId(lenderId).subscribe((floorRate) => {
      this.prepareFloorRateForms([floorRate]);
    });
  }

  private prepareFloorRateForms(floorRates: FloorRateViewModel[]) {
    // If there are any existing valueChange subscriptions, complete them as the forms will get re-created
    this.formUnsubscribe.next();

    this.unsavedChangesChange.emit(false);

    if (this.excludeLendingTypes?.length) {
      // Exclude unneeded lending types
      this.floorRateForms = floorRates
        .filter(fr => !this.excludeLendingTypes
          .map(lendingType => lendingType.toLowerCase())
          .includes(fr.lendingTypeName.toLowerCase()))
        .map(fr => this.createFormGroup(fr));
    } else {
      this.floorRateForms = floorRates.map(fr => this.createFormGroup(fr));
    }
  }

  private createFormGroup(floorRate: FloorRateViewModel): UntypedFormGroup {
    const form = this.fb.group({
      id: floorRate.id,
      lenderId: floorRate.lenderId,
      lendingTypeName: floorRate.lendingTypeName,
      fixedFloorRateEnabled: floorRate.fixedFloorRateEnabled,
      floorRateBaseEnabled: floorRate.floorRateBaseEnabled,
      floorRateBase: new UntypedFormControl(
        floorRate.floorRateBase,
        [FormsValidators.positive, FormsValidators.number],
      ),
      fixedFloorRate: new UntypedFormControl(
        floorRate.fixedFloorRate,
        [FormsValidators.positive, FormsValidators.number],
      ),
      rateTypes: this.fb.array(this.rateTypes.map(type =>
        new FormControl(floorRate.rateTypes.includes(type.rateType) ? type.name : false))),
    });

    form.setValidators(this.atLeastOneRateType);
    if (!floorRate.fixedFloorRateEnabled && !floorRate.floorRateBaseEnabled) {
      form.get('rateTypes')?.disable();
    }

    form.valueChanges.pipe(takeUntil(this.formUnsubscribe)).subscribe(() => this.unsavedChangesChange.emit(true));

    const rateTypeArray = <FormArray>form.controls.rateTypes;
    rateTypeArray.valueChanges.pipe(takeUntil(this.formUnsubscribe)).subscribe(() => {
      rateTypeArray.setValue(
        rateTypeArray.value.map((value, i) => value ? this.rateTypes[i].name : false),
          { emitEvent: false },
      );
    });

    return form;
  }

  public ngOnDestroy() {
    this.formUnsubscribe.next();
    this.formUnsubscribe.complete();
  }

  private atLeastOneRateType(form: FormGroup): ValidationErrors | null {
    const enabled = form.controls['fixedFloorRateEnabled'].value || form.controls['floorRateBaseEnabled'].value;
    if (!enabled) {
        return null;
        }

    const invalid = (form.controls['rateTypes'] as FormArray).controls.every(c => c.value === false);
    return invalid ?
        {'atLeastOneRateTypeMissing': true} :
        null;
}

public rateTypesInvalid(i: number) {
    return this.floorRateForms[i].touched && (this.floorRateForms[i] as FormGroup)?.errors?.atLeastOneRateTypeMissing;
  }
}
