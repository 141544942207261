import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IUiPropertySearchModel } from 'apps/clubhub/src/app/ignite/models/property';
import { IUiProductsSearchModel } from 'apps/clubhub/src/app/ignite/models/products';
import { IUiAffordabilityModel } from 'apps/clubhub/src/app/ignite/models/affordability';
import { SearchType } from 'apps/shared/src/models/affordability-enums';
import { ToolsInUse } from '../models';

@Injectable({
  providedIn: 'root',
})
export class IgniteCommonDataService {
  public constructor(private router: Router) {}

  public propertyModel: IUiPropertySearchModel | null;
  public productModel: IUiProductsSearchModel | null;
  public affordabilityModel: IUiAffordabilityModel | null;
  public source: SearchType | null;
  public isInaccuracyReport = false;
  public isOutcomeHistory = false;
  public searchToolsInUse: ToolsInUse = {
    affordability: false,
    products: false,
    property: false,
    criteria: false,
  };

  public get productsOnly(): boolean {
    return this.router.url.includes('products');
  }

  public reset() {
    this.propertyModel = null;
    this.productModel = null;
    this.affordabilityModel = null;
    this.source = null;
    this.isInaccuracyReport = false;
    this.isOutcomeHistory = false;
    this.searchToolsInUse = {
      affordability: false,
      products: false,
      property: false,
      criteria: false,
    };
  }

  public get isHistoricResult() {
    return this.isInaccuracyReport || this.isOutcomeHistory;
  }
}
