/* eslint-disable no-use-before-define */
import { Injectable } from '@angular/core';
import { clientIds } from '@msslib/constants';
import { keys } from 'apps/clubhub/src/app/constants';
import {
  IdTokenClaims, SigninSilentArgs, SignoutResponse, User, UserManager, UserManagerSettings,
} from 'oidc-client-ts';
import { BehaviorSubject, Observable, concat, from, of } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, take, tap } from 'rxjs/operators';

import { applicationPaths } from '../constants';
import { Mutable } from '../helpers/type-helpers';
import { ConfigService } from './config.service';
import { JwtHelperService } from './jwt-decode';
import { setUser as setSentryUser } from '../../../../../sentry';

export type IAuthenticationResult =
  SuccessAuthenticationResult | FailureAuthenticationResult | RedirectAuthenticationResult;

export interface SuccessAuthenticationResult {
  status: AuthenticationResultStatus.Success;
  state: any;
}

export interface FailureAuthenticationResult {
  status: AuthenticationResultStatus.Fail;
  message: string;
}

export interface RedirectAuthenticationResult {
  status: AuthenticationResultStatus.Redirect;
}

export enum AuthenticationResultStatus {
  Success,
  Redirect,
  Fail,
}

// Disable naming convention as these are defined by the JWT and may not follow the standards we normally use
/* eslint-disable @typescript-eslint/naming-convention */
export interface IUser extends IdTokenClaims {
  agencyNumber?: string;
  lender: string;
  firmName: string;

  client_id: string;
  clubHub: string;
  fcaNumber: string;
  termsDisclaimerAccepted: string;
  marketingPreferencesSaved: string;
  crmSystemInformationSaved: string;
  role: string[];
  sso_user?: string;
}
/* eslint-enable @typescript-eslint/naming-convention */

@Injectable({
  providedIn: 'root',
})
export class AuthorizeService {
  // By default pop ups are disabled because they don't work properly on Edge.
  // If you want to enable pop up authentication simply set this flag to false.

  private popUpDisabled = true;
  private userManagerPromise: Promise<UserManager>;
  private userSubject = new BehaviorSubject<IUser | null>(null);
  public user: IUser | null;
  public historyView = false;

  public constructor(private configService: ConfigService) {
    this.userSubject.subscribe(user => {
      setSentryUser(user ? {
        // Not adding email because it is PII, but IDs/client IDs are fine
        id: user.sub,
        externalClientId: user.sso_user,
        lenderId: user.lender,
      } : null);
    });
  }

  private get userManager(): Promise<UserManager> {
    if (this.userManagerPromise === undefined) {
      this.userManagerPromise = new Promise(async (resolve, reject) => {
        const url =
          this.configService.config.identityServerUrl +
          applicationPaths.apiAuthorizationClientConfigurationUrl +
          this.configService.applicationName;
        const response = await fetch(url);
        if (!response.ok) {
          reject(new Error(`Could not load settings for '${this.configService.applicationName}'`));
          return;
        }

        const settings: Mutable<UserManagerSettings> = await response.json();
        settings.automaticSilentRenew = true;
        settings.includeIdTokenInSilentRenew = true;
        settings.checkSessionIntervalInSeconds = 5; // 5 seconds is maximum as per the oidc library used

        const userManager = new UserManager(settings);

        userManager.events.addUserSignedOut(async () => {
          await userManager.removeUser();
          this.userSubject.next(null);
        });

        resolve(userManager);
      });
    }

    return this.userManagerPromise;
  }

  public isAuthenticated(): Observable<boolean> {
    return this.getUser().pipe(map((u: IUser | null) => !!u));
  }

  public getUser(): Observable<IUser | null> {
    return concat(
      this.userSubject.pipe(
        take(1),
        filter((u) => !!u),
        tap((u) => (this.user = u)),
      ),
      this.getUserFromStorage().pipe(
        filter((u: IUser) => !!u),
        tap((u) => {
          this.user = u;
          this.userSubject.next(u);
        }),
      ),
      this.userSubject.asObservable(),
    );
  }

  public getUserDistinct(): Observable<IUser | null> {
    return this.getUser().pipe(
      distinctUntilChanged((a, b) => a?.sub === b?.sub),
    );
  }

  public get getUserInformation() {
    return this.user;
  }

  public get agencyNumber() {
    const agencyNumber = parseInt(this.user?.agencyNumber ?? '');
    return isNaN(agencyNumber) ? null : agencyNumber;
  }

  public setAgencyNumber(newAgencyNumber: string | undefined) {
    if (!this.user) {
      return;
    }

    if (newAgencyNumber?.length) {
      this.user.agencyNumber = newAgencyNumber;
      sessionStorage.setItem(keys.agencyNumber, newAgencyNumber);
    } else {
      delete this.user.agencyNumber;
      sessionStorage.removeItem(keys.agencyNumber);
    }
  }

  public setUser(user: IUser): Observable<IUser | null> {
    this.userSubject.next(user);
    return this.getUser();
  }

  public readonly allowCriteriaPlus = true;

  public get isLoggedIn(): boolean {
    return !!this.user;
  }

  public get tokenKey() {
    return `oidc.user:${this.configService.config.identityServerUrl}:${this.configService.applicationName}`;
  }

  public get tokenEndpoint() {
    return `${this.configService.config.identityServerUrl}/connect/token`;
  }

  public get isSsoUser$(): Observable<boolean | null> {
    return this.getUser().pipe(map((user: IUser) => user && user.sso_user === 'true'));
  }

  public get isSsoUser(): boolean {
    return this.user?.sso_user === 'true';
  }

  public get isS365Client(): boolean {
    return this.user?.client_id === clientIds.smartr365;
  }

  public get clientId(): string | undefined {
    return this.user?.client_id;
  }

  public hasRole(role: string): boolean {
    return (
      this.user?.role &&
      (
        Array.isArray(this.user.role)
          ? this.user.role.some((x) => x === role)
          : this.user.role === role
      )
    ) ?? false;
  }

  public getAccessToken(): Observable<string | null> {
    return from(this.userManager).pipe(
      mergeMap(userManager => from(userManager.getUser())),
      map((user) => user?.access_token ?? null),
    );
  }

  // We try to authenticate the user in three different ways:
  // 1) We try to see if we can authenticate the user silently. This happens
  //    when the user is already logged in on the IdP and is done using a hidden iframe
  //    on the client.
  // 2) We try to authenticate the user using a PopUp Window. This might fail if there is a
  //    Pop-Up blocker or the user has disabled PopUps.
  // 3) If the two methods above fail, we redirect the browser to the IdP to perform a traditional
  //    redirect flow.
  public async signIn(state: unknown): Promise<IAuthenticationResult> {
    const userManager = await this.userManager;
    let user: User | null;
    try {
      user = await userManager.signinSilent(this.createArguments());
      if (!user) {
        return this.error('No user');
      }
      this.userSubject.next(user.profile as IUser);
      return this.success(state);
    } catch (silentError) {
      // User might not be authenticated, fallback to popup authentication
      // eslint-disable-next-line no-console
      console.log('Silent authentication error: ', silentError);

      try {
        if (this.popUpDisabled) {
          throw new Error(
            'Popup disabled. Change \'authorize.service.ts:AuthorizeService.popupDisabled\' to false to enable it.',
          );
        }
        user = await userManager.signinPopup(this.createArguments());
        this.userSubject.next(user.profile as IUser);
        return this.success(state);
      } catch (popupError) {
        if (popupError.message === 'Popup window closed') {
          // The user explicitly cancelled the login action by closing an opened popup.
          return this.error('The user closed the window.');
        } else if (!this.popUpDisabled) {
          // eslint-disable-next-line no-console
          console.log('Popup authentication error: ', popupError);
        }

        // PopUps might be blocked by the user, fallback to redirect
        try {
          await userManager.signinRedirect(this.createArguments(state));
          return this.redirect();
        } catch (redirectError) {
          // eslint-disable-next-line no-console
          console.log('Redirect authentication error: ', redirectError);
          return this.error(redirectError);
        }
      }
    }
  }

  public async completeSignIn(url: string): Promise<IAuthenticationResult> {
    try {
      const userManager = await this.userManager;
      const user = await userManager.signinCallback(url);
      this.userSubject.next(user as unknown as IUser && ((user as unknown as IUser).profile as unknown as IUser));
      return this.success(user?.state);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('There was an error signing in: ', error);
      return this.error('There was an error signing in.');
    }
  }

  public async signOut(state: unknown): Promise<IAuthenticationResult> {
    const userManager = await this.userManager;
    try {
      if (this.popUpDisabled) {
        throw new Error(
          'Popup disabled. Change \'authorize.service.ts:AuthorizeService.popupDisabled\' to false to enable it.',
        );
      }
      await userManager.signoutPopup(this.createArguments());
      this.userSubject.next(null);
      return this.success(state);
    } catch (popupSignOutError) {
      // eslint-disable-next-line no-console
      console.log('Popup signout error: ', popupSignOutError);
      try {
        await userManager.signoutRedirect(this.createArguments(state));
        return this.redirect();
      } catch (redirectSignOutError) {
        // eslint-disable-next-line no-console
        console.log('Redirect signout error: ', popupSignOutError);
        return this.error(redirectSignOutError);
      }
    }
  }

  public async completeSignOut(url: string): Promise<IAuthenticationResult> {
    const userManager = await this.userManager;
    try {
      const state: SignoutResponse | any = await userManager.signoutCallback(url);
      this.userSubject.next(null);
      sessionStorage.clear();
      return this.success(!!state && state.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(`There was an error trying to log out '${error}'.`);
      return this.error(error);
    }
  }

  private createArguments(state?: unknown): SigninSilentArgs {
    return { state };
  }

  private error(message: string): IAuthenticationResult {
    return { status: AuthenticationResultStatus.Fail, message };
  }

  private success(state: unknown): IAuthenticationResult {
    return { status: AuthenticationResultStatus.Success, state };
  }

  private redirect(): IAuthenticationResult {
    return { status: AuthenticationResultStatus.Redirect };
  }

  private getUserFromStorage(): Observable<IUser> {
    return from(this.userManager).pipe(
      mergeMap(um => um.getUser()),
      mergeMap(() => this.getExternalUser()),
      map((u) => u?.profile),
    );
  }

  private getExternalUser(): Observable<any> {
    const item = sessionStorage.getItem(this.tokenKey);
    const offlineToken = item ? JSON.parse(item) : null;
    if (offlineToken) {
      const service = new JwtHelperService();
      const profile = service.decodeToken(offlineToken.access_token);
      const agencyNumberInSession = sessionStorage.getItem(keys.agencyNumber);
      if (agencyNumberInSession && profile.sso_user === 'true') {
        profile.agencyNumber = agencyNumberInSession;
      }
      return of({ profile });
    }
    return of(null);
  }
}
