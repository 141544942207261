import { FeeCalculationScenario, ProductFeeType } from '@msslib/constants';
import { BdgAffordabilityRequest } from 'apps/clubhub/src/app/ignite/models/affordability';
import { ProductsBridgingModelRequest } from 'apps/clubhub/src/app/ignite/models/products';
import {
  BridgingProduct,
  BridgingProductFeeType,
  BridgingProductFeeTypeModal,
  LoanType,
  MortgageType,
  Product,
  RepaymentMethod,
} from 'apps/shared/src/models';
import { LoanPeriod } from './loan-period';
import { CustomBridgingProductFees, CustomFee, CustomProductFees } from '@msslib/models/custom-product-calculations';

export interface LoanRequestModel {
  repaymentMethod?: RepaymentMethod;
  loanAmount?: number;
  interestOnlyAmount?: number;
  mortgageTermYears?: number;
  mortgageTermMonths?: number;
}

export class Loan {
  public constructor(
    public repaymentLoanAmount: number,
    public interestOnlyLoanAmount: number,
    public loanFees: number,
    public additionalFees: number,
    public cashback: number,
    public term: number,
    public loanPeriods: LoanPeriod[],
  ) {}

  /** Creates a new loan from a product and a search request. */
  public static fromProduct(
    product: Product,
    model: LoanRequestModel,
    includeCashback = true,
    fees: CustomProductFees[] | null = null,
    customFees: CustomFee[],
    assumedLegalFee: number | null,
  ): Loan {
    const [interestOnlyLoanAmount, repaymentLoanAmount] = this.getLoanAmount(model);
    const loanPeriods = this.getLoanPeriodsFromProduct(product);
    const term = (model.mortgageTermYears ?? 0) * 12 + (model.mortgageTermMonths ?? 0);
    const cashback = includeCashback ? (product.cashback ?? 0) : 0;

    const assumedLegalFeeAmount =
      product.mortgage_type?.includes(MortgageType.Remortgage) && !product.freeLegal ? (assumedLegalFee ?? 0) : 0;

    const sumFees: (fees: CustomProductFees[]) => number = feeTypes => feeTypes
      .map((fee) => {
        if (fee.feeType === ProductFeeType.Valuation && product.valRefundProductIncentive === true) {
          // Subtract valuation fee if valRefundProductIncentive is true
          return 0;
        }
        if (fee.feeName === 'Assumed Legal Fee') {
          return assumedLegalFeeAmount;
        }
        const currentCustomFees = customFees?.filter(x => x.feeName === fee.feeName);
        if (currentCustomFees?.length > 0) {
          return currentCustomFees.reduce((total, currentCustomFee) => {
            let feeAmount = 0;
            // Below a certain threshold treat feeAmount a % of the loan
            if (currentCustomFee.feeAmount > 2) {
              feeAmount = currentCustomFee.feeAmount;
            } else {
              feeAmount = currentCustomFee.feeAmount * (model.loanAmount ?? 0);
            }
            return feeAmount + total;
          },0);
        }

        return product.fees[fee.feeType] ?? 0;
      })
      .filter(feeValue => feeValue !== -1)
      .reduce((fee, total) => fee + total, 0);

    const loanFees =
      sumFees(fees?.filter(fee => fee.feeCalculationScenario === FeeCalculationScenario.AddToLoan) ?? []);
    const additionalFees =
      sumFees(fees?.filter(fee => fee.feeCalculationScenario === FeeCalculationScenario.OneOffCost) ?? []);

    return new Loan(repaymentLoanAmount, interestOnlyLoanAmount, loanFees, additionalFees, cashback,
      term, loanPeriods);
  }

  public static fromBridgingProduct(
    product: BridgingProduct,
    model: BdgAffordabilityRequest
      | ProductsBridgingModelRequest,
    fees: CustomBridgingProductFees[] | null = null,
  ): Loan {
    const loanPeriods = this.getBridgingLoanPeriods(product, model);

    function sumFeesBridging(feeTypes: BridgingProductFeeTypeModal[]) {
      let total = 0;

      product.fees.forEach(fee => {
        feeTypes.forEach(feeType => {
          if (BridgingProductFeeType[fee.type].toLowerCase() === feeType && fee.value !== -1) {
            total += fee.value;
          }
        });
      });
      return total;
    }

    const loanFees = sumFeesBridging(fees?.filter(fee=>fee.feeCalculationScenario === FeeCalculationScenario.AddToLoan)
      .map(fee => fee.feeName) ?? []);
    const additionalFees = sumFeesBridging(fees?.filter(
      fee=>fee.feeCalculationScenario === FeeCalculationScenario.OneOffCost).map(fee => fee.feeName) ?? []);

    const loanAmount = model.loanAmount ?? 0;
    const loanTerm = model.loanTerm ?? 0;

    return new Loan(0, loanAmount, loanFees, additionalFees, 0, loanTerm, loanPeriods);
  }

  public get loanType(): LoanType | null {
    switch (true) {
      case (this.repaymentLoanAmount === 0 && this.interestOnlyLoanAmount > 0):
        return LoanType.InterestOnly;
      case (this.repaymentLoanAmount > 0 && this.interestOnlyLoanAmount === 0):
        return LoanType.Repayment;
      case (this.repaymentLoanAmount > 0 && this.interestOnlyLoanAmount > 0):
        return LoanType.InterestPartAndPart;
      default:
        return null;
    }
  }

  public static getLoanAmount(
    model: Pick<LoanRequestModel, 'repaymentMethod' | 'loanAmount' | 'interestOnlyAmount'>,
  ) : [number, number] {
    switch (model.repaymentMethod) {
      case RepaymentMethod.CapitalAndInterest:
        return [0, model.loanAmount ? +model.loanAmount : 0];
      case RepaymentMethod.InterestOnly:
        return [model.loanAmount ? +model.loanAmount : 0, 0];
      case RepaymentMethod.InterestOnlyPartAndPart:
        return [
          model.interestOnlyAmount ? +model.interestOnlyAmount : 0,
          (model.loanAmount ? +model.loanAmount : 0) - (model.interestOnlyAmount ? +model.interestOnlyAmount : 0),
        ];
      default:
        return [0,0];
    }
  }

  public static getLoanPeriodsFromProduct(product: Product): LoanPeriod[] {
    const productInitialPeriodRates = product.rates
      .map(productRate => ({
        index: productRate.ordinal,
        rate: productRate.rate,
        until: productRate.until ? new Date(Date.parse(productRate.until)) : null,
        initialPeriod: productRate.initialPeriod,
      }))
      .filter(periodRate => periodRate.rate);

    let prevPeriodDate: Date = new Date(new Date().toJSON().slice(0,10).replace(/-/g,'/'));
    const loanPeriods: LoanPeriod[] = [];
    productInitialPeriodRates.forEach(periodRate => {
      if (!periodRate.rate) {
        return;
      }

      // If an until date is provided, prefer that over the 'initialPeriod'
      if (periodRate.until) {
        const monthDiff = this.monthDiff(prevPeriodDate, periodRate.until);
        const sharedRate = periodRate.rate / 100;
        loanPeriods.push({
          term: monthDiff,
          repaymentRate: sharedRate,
          interestOnlyRate: sharedRate,
          until: periodRate.until,
        } as LoanPeriod);
        prevPeriodDate = periodRate.until;

      // If an until date is not provided, fallback to the initialPeriod
      } else if (periodRate.initialPeriod) {
        const sharedRate = periodRate.rate / 100;
        loanPeriods.push({
          term: product.initialPeriod,
          repaymentRate: sharedRate,
          interestOnlyRate: sharedRate,
        } as LoanPeriod);
        prevPeriodDate?.setMonth(prevPeriodDate.getMonth() + periodRate.initialPeriod);

      // Special handling for LAST period when until and initialPeriod aren't provided, but revert rate is not set or
      // matches the rate
      } else if (periodRate.index === productInitialPeriodRates.length - 1 &&
        (product.revertRate === null || product.revertRate === periodRate.rate)) {
        // Assert that revert rate is rate1 and then break out of the loop so that it runs the final code to add the
        // revert rate as a -1 length period.
        // eslint-disable-next-line camelcase
        product.revertRate = periodRate.rate;
      }
    });

    if ((!productInitialPeriodRates[0].initialPeriod || productInitialPeriodRates[0].initialPeriod === 0)
    && !productInitialPeriodRates[0].until && productInitialPeriodRates[0].rate !== null) {
      loanPeriods.push({
        term: -1,
        repaymentRate: productInitialPeriodRates[0].rate / 100,
        interestOnlyRate: productInitialPeriodRates[0].rate / 100,
      } as LoanPeriod);
      return loanPeriods;
    }


    if (product.revertRate) {
      loanPeriods.push({
        term: -1,
        repaymentRate: product.revertRate / 100,
        interestOnlyRate: product.revertRate / 100,
      } as LoanPeriod);
    }
    // eslint-disable-next-line camelcase
    product.revertRate = product.revertRate ? +product.revertRate.toFixed(2) : null;
    return loanPeriods;
  }

  private static getBridgingLoanPeriods(
    product: BridgingProduct, model: BdgAffordabilityRequest | ProductsBridgingModelRequest,
  ): LoanPeriod[] {
    const loanPeriods: LoanPeriod[] = [];
    const term = model.loanTerm ?? 0;

    product.rates?.forEach(rate => {
      const sharedRate = rate.rate / 100 * term;
      loanPeriods.push({
        term: model.loanTerm,
        repaymentRate: sharedRate,
        interestOnlyRate: sharedRate,
      } as LoanPeriod);
    });

    return loanPeriods;
  }

  private static monthDiff(d1: Date, d2: Date): number {
    let months: number;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }
}
