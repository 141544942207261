import { Injectable } from '@angular/core';
import {
  BridgingLenderProducts, MatchedLenderProducts, ProductTransferInfoModel, Schedule,
} from 'apps/shared/src/models';
import {
  ILoadingOptions,
  ProductCollectionType,
  ProductUploadAdditionalResult,
  ProductUploadType,
  UploadedProductsResult,
  ValuationFeeScale,
  ValuationFeeScaleEntry,
  ValuationFeeScaleUpdateRequest,
} from '@msslib/models';
import { Observable, map } from 'rxjs';
import { LenderHubDataService } from '@msslib/services/lenderhub-data.service';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FieldTypes } from '@msslib/models/forms';
import isAfter from 'date-fns/esm/isAfter';
import isBefore from 'date-fns/esm/isBefore';
import parseISO from 'date-fns/esm/parseISO';
import {
  PackagerVariableRateCodeViewModel,
  VariableRateCodeLookupUpdateRequest,
  VariableRateCodeLookupViewModel,
  VariableRateCodeUsageViewModel,
} from '@msslib/models/variable-rate-codes';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  public constructor(private lenderHubDataService: LenderHubDataService) {}
  public unsavedChanges = false;
  public productTransferForm = new FormGroup({});
  public productTransferOptions: FormlyFormOptions = {};
  public verified = false;
  public verifyError = false;
  public productTransferFields: FormlyFieldConfig[] = [
    {
      key: 'supportingDocuments',
      type: FieldTypes.TextArea,
      props: {
        label: 'Required supporting documents',
        tooltip: 'Any extra documentation the applicant needs to provide for a product transfer.',
        rows: 3,
        required: true,
      },
    },
    {
      key: 'timeBeforeApplicable',
      type: FieldTypes.Number,
      props: {
        label: 'When can a customer apply for a Product Transfer?',
        tooltip:
          'How long before the end of the mortgage term can a customer change/ apply/go live with a product transfer.',
        addonEnd: 'Days',
        required: true,
      },
    },
    {
      key: 'additionalNotes',
      type: FieldTypes.Textbox,
      props: {
        label: 'Additional Notes for When can a customer apply for a Product Transfer',
      },
    },
    {
      key: 'whenContactCustomer',
      type: FieldTypes.Number,
      props: {
        label: 'When do you contact the customer?',
        tooltip: 'How long before the end of the mortgage term do you contact the customer.',
        addonEnd: 'Days',
        required: true,
      },
    },
    {
      key: 'processFurtherBorrowing',
      type: FieldTypes.TextArea,
      props: {
        label: 'Process for further borrowing',
        tooltip: 'What is your process for further borrowing on a product transfer.',
        rows: 3,
        required: true,
      },
    },
    {
      key: 'processMissedPayments',
      type: FieldTypes.TextArea,
      props: {
        label: 'Process for missed mortgage payments',
        tooltip: 'What is your process for missed mortgage payments during the current mortgage ' +
        'term and a customer is wanting to do a product transfer.',
        rows: 3,
        required: true,
      },
    },
    {
      key: 'retentionUrl',
      type: FieldTypes.Textbox,
      props: {
        change: (formControl: FormlyFieldConfig) => {
          this.verified = false;
          (formControl.parent?.form?.controls as any).retentionUrlVerify.setValue(false);
        },
        pattern: /https:\/\/www.*/,
        label: 'Lender retention URL (Please include the full url i.e. \'https://...)\'',
        tooltip: 'Please provide the URL link to your Product transfer page. If you do not have one, leave blank.',
        addonEndButton: {
          text: 'Verify',
          clickAction: this.openUrl.bind(this),
        },
      },
      validation: {
        messages: {
          pattern: (error: unknown, field: FormlyFieldConfig) =>
            `"${field.formControl?.value}" is not a valid URL`,
        },
      },
    },
    {
      key: 'retentionUrlVerify',
      type: FieldTypes.Checkbox,
      defaultValue: this.verified,
      props: {
        change: () => this.verifyError = false,
        label: 'I confirm the URL above is correct',
      },
      expressions: {
        'props.disabled': () => !this.verified,
      },
    },
  ];
  public productTransferModel: ProductTransferInfoModel;

  public openUrl() {
    const url = (this.productTransferForm.value as any).retentionUrl;
    if (url) {
      this.verified = true;
      this.verifyError = false;
      (this.productTransferForm.controls as any).retentionUrlVerify.updateValueAndValidity();
      window.open(url, '_blank');
    }
  }

  public uploadProducts(
    file: File,
    type: ProductCollectionType,
    uploadType: ProductUploadType,
    preview: boolean,
  ): Observable<UploadedProductsResult>;
  public uploadProducts(
    file: File,
    type: ProductCollectionType,
    uploadType: ProductUploadType,
    preview: boolean,
    scheduleDateTime: string | null,
    variableRateCodes: VariableRateCodeLookupUpdateRequest | null,
    hasMissingVariableRateCodes: boolean | null,
  ) : Observable<ProductUploadAdditionalResult>;
  public uploadProducts(
    file: File,
    type: ProductCollectionType,
    uploadType: ProductUploadType = ProductUploadType.Core,
    preview: boolean,
    scheduleDateTime: string | null = null,
    variableRateCodes: VariableRateCodeLookupUpdateRequest | null = null,
    hasMissingVariableRateCodes: boolean | null = null,
  ) {
    const formData: FormData = new FormData();
    formData.append('fileKey', file, file.name);

    if (scheduleDateTime) {
      formData.append('scheduleDateTime', scheduleDateTime);
    }

    const formDataAppendObject = (value: unknown, path: string = '') => {
      if (typeof value === 'object') {
        for (const key in value) {
          formDataAppendObject(value[key], path ? `${path}[${key}]` : key);
        }
      } else if (value !== undefined && value !== null) {
        formData.append(path, value.toString());
      }
    };
    formDataAppendObject(variableRateCodes, 'variableRateCodesUpdate');
    formDataAppendObject(hasMissingVariableRateCodes, 'hasMissingVariableRateCodes');
    const uploadUrl = `Product/ProductUpload/${type}?uploadType=${uploadType}${preview ? '&preview=true' : '' }`;

    return this.lenderHubDataService
      .post<UploadedProductsResult | ProductUploadAdditionalResult>(
        uploadUrl,
        formData,
        null,
        {
          showErrorToast: false,
        },
      );
  }

  public deleteSchedule(type: ProductCollectionType, scheduleId: string): Observable<void> {
    return this.lenderHubDataService.delete(`Product/DeleteSchedule/${type}?scheduleId=${scheduleId}`);
  }

  public updateSchedule(type: ProductCollectionType, scheduleId: string, scheduleDateTime: string): Observable<void> {
    const formData: FormData = new FormData();
    formData.append('scheduleDateTime', scheduleDateTime ?? '');

    const url = `Product/UpdateSchedule/${type}?scheduleId=${scheduleId}`;
    return this.lenderHubDataService.post(url, formData, null, { showErrorToast: false });
  }

  public getAllBridgingProducts(uploadType: ProductUploadType): Observable<BridgingLenderProducts> {
    return this.getAllProducts(ProductCollectionType.Bridging, uploadType) as Observable<BridgingLenderProducts>;
  }

  public getAllResAndBtlProducts(uploadType: ProductUploadType): Observable<MatchedLenderProducts> {
    return this.getAllProducts(ProductCollectionType.ResiBtl, uploadType) as Observable<MatchedLenderProducts>;
  }

  public getAllProducts(
    type: ProductCollectionType,
    uploadType: ProductUploadType,
  ): Observable<MatchedLenderProducts | BridgingLenderProducts> {
    return this.lenderHubDataService
      .get<MatchedLenderProducts | BridgingLenderProducts>(`Product/GetAllProducts/${type}?uploadType=${uploadType}`);
  }

  public getScheduledBridgingProducts(uploadType: ProductUploadType): Observable<BridgingLenderProducts> {
    return this.getScheduledProducts(ProductCollectionType.Bridging, uploadType) as Observable<BridgingLenderProducts>;
  }

  public getScheduledResAndBtlProducts(uploadType: ProductUploadType): Observable<MatchedLenderProducts> {
    return this.getScheduledProducts(ProductCollectionType.ResiBtl, uploadType) as Observable<MatchedLenderProducts>;
  }

  public getScheduledProducts(
    type: ProductCollectionType,
    uploadType: ProductUploadType,
  ): Observable<MatchedLenderProducts | BridgingLenderProducts> {
    return this.lenderHubDataService.get<MatchedLenderProducts | BridgingLenderProducts>(
      `Product/GetScheduledProducts/${type}?uploadType=${uploadType}`,
    );
  }

  public getScheduled(
    type: ProductCollectionType,
    uploadType: ProductUploadType,
  ): Observable<Record<'current' | 'future', Schedule | undefined>> {
    return this.lenderHubDataService.get<Schedule[]>(
      `Product/GetScheduled/${type}/?uploadType=${uploadType}`,
      { loading: false },
    ).pipe(map(schedules => this.findCurrentFutureSchedules(schedules)));
  }

  public getHasLiveProducts(type: ProductCollectionType, uploadType: ProductUploadType): Observable<boolean> {
    return this.lenderHubDataService
      .get<boolean>(`Product/HasLiveProducts/${type}?uploadType=${uploadType}`, { loading: false });
  }

  public get visibleProductLendingTypes(): Observable<Record<string, boolean>> {
    return this.lenderHubDataService.get<Record<string, boolean>>('Product/Visibility');
  }

  public setProductLendingTypeVisible(lendingType: string, showProducts: boolean) {
    return this.lenderHubDataService.post<unknown>(`Product/Visibility/${lendingType}`, { showProducts });
  }

  public getValuationFeeScales(lendingTypeCode: string) {
    return this.lenderHubDataService
      .get<ValuationFeeScale[]>(`Product/ValuationFeeScale/${lendingTypeCode}`);
  }

  public updateValuationFeeScale(lendingTypeCode: string,
    valuationFeeScaleEntries: ValuationFeeScaleEntry[],
    scheduleDate: string | Date | null = null,
  ) {
    return this.lenderHubDataService
      .post(`Product/ValuationFeeScale/${lendingTypeCode}`, {
        entries: valuationFeeScaleEntries,
        scheduleDate,
      } as ValuationFeeScaleUpdateRequest);
  }

  public updateScheduledValuationFeeScaleDate(lendingTypeCode: string, newDate: string | Date) {
    return this.lenderHubDataService.put(`Product/ValuationFeeScale/${lendingTypeCode}/Schedule`, newDate);
  }

  public deleteScheduledValuationFeeScale(lendingTypeCode: string) {
    return this.lenderHubDataService.delete(`Product/ValuationFeeScale/${lendingTypeCode}/Schedule`);
  }

  public findCurrentFutureSchedules(schedules: Schedule[] | undefined)
    : Record<'current' | 'future', Schedule | undefined> {
    return {
      current: schedules?.find(s => s.startTime && isBefore(parseISO(s.startTime), Date.now())),
      future: schedules?.find(s => s.startTime && isAfter(parseISO(s.startTime), Date.now())),
    };
  }

  public getVariableRateCodes(opt?: ILoadingOptions): Observable<VariableRateCodeLookupViewModel> {
    return this.lenderHubDataService.get<VariableRateCodeLookupViewModel>('Product/VariableRateCodes', opt);
  }

  public getVariableRateCodesByLenderNameId(lenderNameId: number): Observable<VariableRateCodeLookupViewModel> {
    return this.lenderHubDataService.get<VariableRateCodeLookupViewModel>(`Product/VariableRateCodes/${lenderNameId}`);
  }

  public updateVariableRateCodes(req: VariableRateCodeLookupUpdateRequest): Observable<void> {
    return this.lenderHubDataService.post('Product/VariableRateCodes', req);
  }
  public updateVariableRateCodesByLenderNameId(lenderNameId: number,
    req: VariableRateCodeLookupUpdateRequest): Observable<void> {
    return this.lenderHubDataService.post(`Product/VariableRateCodes/${lenderNameId}`, req);
  }

  public isVariableCodeInUse(variableRateCode: string): Observable<VariableRateCodeUsageViewModel> {
    return this.lenderHubDataService.get<VariableRateCodeUsageViewModel>(
      `Product/VariableRateCodes/HasExistingProducts/${variableRateCode}`);
  }

  public isVariableCodeInUseByLenderNameId(variableRateCode: string, lenderNameId: number)
    : Observable<VariableRateCodeUsageViewModel> {
    return this.lenderHubDataService
      .get<VariableRateCodeUsageViewModel>(
        `Product/VariableRateCodes/HasExistingProducts/${variableRateCode}/${lenderNameId}`);
  }

  public deleteScheduledVariableRateCodes(): Observable<void> {
    return this.lenderHubDataService.delete('Product/VariableRateCodes/Scheduled');
  }

  public getPackagerVariableRateCodes() {
    return this.lenderHubDataService.get<PackagerVariableRateCodeViewModel[]>('Product/PackagerVariableRateCodes');
  }
}
