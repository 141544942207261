<div [formGroup]="formGroup" [class]="config.cssClass ? 'switch mb-4 ' + config.cssClass: 'form-group switch'">
  <input
    type="checkbox"
    class="form-check-input"
    [checked]="formGroup.value[config.name]"
    [id]="config.name"
    [formControlName]="config.name"
    (click)="changeValue(config.name); config.clickHandler ? config.clickHandler($event) : null"
    (change)="onChange($event)"
  />
  <label class="form-check-label" [for]="config.name">
    <div class="switch-slider me-2"></div>
    <span class="my-auto">{{ config.label }}</span>
  </label>
  <div libFormFieldError class="invalid-feedback" [fieldConfig]="config"></div>
</div>
