// import { Lender } from 'apps\lenderhub\src\app\models/lenders.ts';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LendingType } from '@msslib/models/lending-type';
import { ClubHubDataService } from '@msslib/services/clubhub-data.service';
import {
  LenderName,
  LenderNameViewModel,
  LenderNetwork,
  LenderProductsConfig,
  LenderProductsConfigWithValidation,
  OtherLenderType,
  ProductUpload,
} from '../models';
import { Lender } from './../models';
import { LenderHubDataService } from '@msslib/services';
import { GridStateModel, SimpleGridResponseViewModel } from '@msslib/models';

@Injectable({
  providedIn: 'root',
})
export class LenderService {
  public constructor(
    private clubHubDataService: ClubHubDataService,
    private lenderHubDataService: LenderHubDataService,
  ) {}

  public getLenderNetworks(lender: Lender) {
    return this.clubHubDataService.get<LenderNetwork[]>(`Lender/GetLenderNetworks/${lender.id}`);
  }

  public downloadDocument(lenderNameId: number, sheetId: number) {
    return this.clubHubDataService.getFile(
      `Lender/DownloadSheet/${lenderNameId}/${sheetId}`,
    );
  }

  public addNetworks(lenderNetworks: LenderNetwork[]) {
    return this.clubHubDataService.post<LenderNetwork[]>('Lender/AddNetworks', lenderNetworks);
  }

  public getLenders(lendingType: LendingType) {
    return this.clubHubDataService.get<Lender[]>(`Lender/GetLenders/${lendingType.code}`);
  }

  public getUnarchivedLenders(lendingType: LendingType) {
    return this.clubHubDataService.get<Lender[]>(`Lender/GetUnarchivedLenders/${lendingType.code}`);
  }

  public getAllManagementLenders() {
    return this.clubHubDataService.get<LenderNameViewModel[]>('Lender/AllManagementLenders');
  }

  public getUnarchivedLendersNames(lendingType: LendingType) {
    return this.clubHubDataService.get<LenderName[]>(`Lender/GetUnarchivedLenderNames/${lendingType.code}`);
  }

  public getLender(lenderId: number) {
    return this.clubHubDataService.get<Lender>(`Lender/${lenderId}`);
  }

  public getLenderProductsConfig(lenderId: number) {
    return this.clubHubDataService.get<LenderProductsConfigWithValidation>(`Lender/ProductsConfig/${lenderId}`);
  }

  public getLenderHasBespokeHandler(lenderId: number) {
    return this.lenderHubDataService.get<boolean>(`LenderName/HasBespokeHandler/${lenderId}`);
  }

  public updateLenderConfig(lenderId: number, lender: LenderProductsConfig) {
    return this.clubHubDataService.put<Lender>(`Lender/ProductsConfig/${lenderId}`, lender, {
      // Manually handle the errors, since the endpoint will throw a validation error if the details are invalid.
      // Do not want to show an error toast in this case
      showErrorToast: false,
    });
  }

  public updateLender(lenderId: number, lender: Lender) {
    return this.clubHubDataService.put<Lender>(`Lender/Update/${lenderId}`, lender);
  }

  public getPartialFlow(id: number) {
    return this.clubHubDataService.get(`Answer/Children/${id}`);
  }

  public updateLenderOutcome(changes: unknown) {
    return this.clubHubDataService.post('Lender/UpdateLenderOutcomes', changes);
  }

  public uploadLogo(lenderId: number, fileType: string, logo: unknown) {
    fileType = fileType
      ? fileType.replace('image/', '').replace('+xml', '')
      : 'jpeg';
    return this.clubHubDataService.postForText(`Lender/UploadLogo/${lenderId}/${fileType}`, logo);
  }

  public removeLogo(lenderId: number) {
    return this.clubHubDataService.delete(`Lender/RemoveLogo/${lenderId}`);
  }

  public getLenderDomains(lenderNameId: number): Observable<string[]> {
    return this.clubHubDataService.get<string[]>(`LenderDomain/${lenderNameId}`);
  }

  public addLenderDomain(lenderNameId: number, domain: string) {
    return this.clubHubDataService.post('LenderDomain', { lenderNameId, domain });
  }

  public deleteLenderDomain(lenderNameId: number, domain: string) {
    return this.clubHubDataService.delete(`LenderDomain/${lenderNameId}/${domain}`);
  }

  public getOtherLenderTypes(lenderId: number) {
    return this.clubHubDataService.get<OtherLenderType[]>(`Lender/OtherLenderTypes/${lenderId}`);
  }

  public addLenderName(lender: LenderNameViewModel) {
    return this.clubHubDataService.post('Lender/LenderName', lender);
  }

  public updateLenderName(lender: LenderNameViewModel) {
    return this.clubHubDataService.put('Lender/LenderName', lender);
  }

  public getProductSheetUploads(lenderId: number, gridState: GridStateModel) {
    return this.clubHubDataService.post<SimpleGridResponseViewModel<ProductUpload>>(
      `Lender/ProductUploads/${lenderId}`,
      gridState,
      { loading: false });
  }
}
