export enum LenderAuditType {
  Ignite = 1,
  Products = 2,
  ProductUpload = 3,
  Affordability = 4,
}

export enum LenderAuditResult {
  NotApplicable = 0,
  Approved = 1,
  ErrorReported = 2,
  NotCompleted = 3,
  AffordabilityUpdate = 4,
}

export interface LenderAuditLogAdditionalData {
  changedExpressions: string[];
}

export interface LenderAuditLog {
  id: number;
  referenceId: string;
  type: LenderAuditType;
  result: LenderAuditResult;
  lendingType: string;
  createdBy: string;
  createdDate: string;
  additionalData: LenderAuditLogAdditionalData;
}

export interface CreateLenderAuditLogRequest {
  type: LenderAuditType;
  result: LenderAuditResult;
  referenceId: string | null | undefined;
  lendingTypeId: number | null | undefined;
}
