import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
@Component({
  selector: 'lib-toggle-switch',
  styleUrls: ['toggle-switch.component.scss'],
  templateUrl: 'toggle-switch.component.html',
})
export class ToggleSwitchComponent implements OnInit {
  @Input() public checked: boolean;
  @Input() public label: string;
  @Input() public disabled: boolean;
  @Output() public changed = new EventEmitter<boolean>();
  public id: number;

  public ngOnInit() {
    this.id = Math.floor(Math.random() * 10000) + 1;
  }

  public changeHandler(e: { target: { checked: boolean } }) {
    this.changed.emit(e.target.checked);
  }
}
