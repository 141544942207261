import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import {
  OperandMetadata,
  OperatorMetadata,
  PropertyCondition,
  PropertyMetadata,
  RulesEngineSchema,
  alwaysConditionPropertyName,
  validatePropertyCondition,
} from '@msslib/models/rules-engine';
import { coerceType } from '../utils';

@Component({
  selector: 'lib-rules-engine-condition-editor',
  templateUrl: 'condition-editor.component.html',
  styleUrls: ['condition-editor.component.scss'],
})
export class RulesEngineConditionEditorComponent implements OnChanges {
  @Input({ required: true }) public schema: RulesEngineSchema;

  @Input({ required: true }) public condition: Partial<PropertyCondition>;
  @Output() public conditionChange = new EventEmitter<Partial<PropertyCondition>>();

  @Input() public showErrors = false;

  @Input() public alwaysConditionLabel = '-Always-';

  public readonly always = alwaysConditionPropertyName;

  public properties: PropertyMetadata[] = [];

  private get propertyMetadata(): PropertyMetadata | undefined {
    return this.condition?.propertyName
      ? this.schema.propertiesMap.get(this.condition.propertyName)
      : undefined;
  }

  /** Which operators (if any) are available based on the selected property. */
  public get availableOperators(): OperatorMetadata[] {
    const propertyMetadata = this.propertyMetadata;
    return propertyMetadata ? this.schema.getOperatorsForType(propertyMetadata.typeName) : [];
  }

  /** Which operands (if any) are active based on the selected operator. */
  public get rightHandOperands(): OperandMetadata[] {
    const propertyMetadata = this.propertyMetadata;
    const operatorMetadata = propertyMetadata && this.condition.operatorName
      && this.schema.getOperator(propertyMetadata.typeName, this.condition.operatorName);
    return operatorMetadata ? operatorMetadata.rightOperandMetadata : [];
  }

  public get errorMessages() {
    return validatePropertyCondition(this.condition as PropertyCondition, this.schema, true);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('schema' in changes) {
      this.properties = [
        // Fake 'Always' property for the $$Always condition.
        {
          name: alwaysConditionPropertyName, displayName: this.alwaysConditionLabel,
          isReadable: true, isWriteable: false,
        }  as PropertyMetadata,
        ...(changes.schema.currentValue as RulesEngineSchema).properties.filter(p => p.isReadable),
      ];
    }
  }

  public setProperty(name: string | undefined) {
    this.patchCondition({ propertyName: name });

    // If a property is now selected, we re-set the operator to ensure that the right hand operands are valid.
    // If no operator was selected, we don't need to re-set it. If the operator is no longer valid for this type, then
    // unset the operator.
    if (this.condition?.operatorName) {
      const isOpValid = this.propertyMetadata
        && this.schema.hasOperatorForType(this.propertyMetadata.typeName, this.condition.operatorName);
      this.setOperator(isOpValid ? this.condition.operatorName : undefined);
    }
  }

  public setOperator(name: string | undefined) {
    // TODO: try preserve operands if possible (i.e. if correct number of operands and all are correct type)
    const operatorMetadata = this.propertyMetadata && name
      ? this.schema.getOperator(this.propertyMetadata.typeName, name)
      : undefined;
    this.patchCondition({
      operatorName: name,
      arguments: operatorMetadata
        ? operatorMetadata.rightOperandMetadata
          .map(op => coerceType(null, this.schema.getType(op.typeName), op.isNullable))
        : undefined,
    });
  }

  public getOperand(index: number) {
    return this.condition.arguments?.[index];
  }

  public setOperand(index: number, value: any) {
    if (this.condition?.arguments) {
      const newArgs = [...this.condition.arguments];
      newArgs[index] = value;
      this.patchCondition({ arguments: newArgs });
    }
  }

  /** Partially updates the condition and, if valid, emits a change event. */
  private patchCondition(condition: Partial<PropertyCondition> | undefined) {
    this.condition = { ...this.condition, ...condition };
    this.conditionChange.emit(this.condition);
  }

  public trackByName(_: number, { name }: { name: string }) {
    return name;
  }

  public trackByIndex(index: number) {
    return index;
  }

  public static isAlways(condition: PropertyCondition) {
    return condition.propertyName === alwaysConditionPropertyName;
  }
}
