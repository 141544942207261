import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFormOptions } from '@ngx-formly/core';
import {
  AffordabilityServiceLoadingViewMode,
  StepName,
} from 'apps/clubhub/src/app/ignite/models/affordability';
import { Product, PropertyOutcomeDetailsDetailed } from 'apps/shared/src/models';
import { ResultsView } from 'apps/clubhub/src/app/ignite/models/affordability/enums';
import { roles } from '@msslib/constants/roles';
import {
  IUiPropertySearchModel,
  PropertyAuditResultsStatus,
  PropertyModelRequest,
} from 'apps/clubhub/src/app/ignite/models/property';
import { AuthorizeService, ClubHubDataService, LendingTypeService, ModalService } from '@msslib/services';
import { SimpleGridResponseViewModel } from '@msslib/models/simple-grid';
import { SimpleGridComponent } from '@msslib/components/simple-grid';
import { FloodRiskData } from 'apps/clubhub/src/app/ignite/models';
import { AddressExtended, LendingType } from '@msslib/models';
import { IgniteCommonDataService } from './ignite-common-data.service';
import { CriteriaService } from './criteria.service';
import { IgniteService } from 'apps/clubhub/src/app/ignite/services';
import { CriteriaV2OutcomeService } from 'apps/clubhub/src/app/ignite/pages/criteria-v2/services';

@Injectable({
  providedIn: 'root',
})
export class PropertySearchService {
  public propertyLoading = true;
  public form = new UntypedFormGroup({});
  public model: IUiPropertySearchModel;
  public options = {} as FormlyFormOptions;
  public currentStepName: StepName;
  public matchedLenders: string[] = [];
  public propertyModelRequest: PropertyModelRequest | null = null;
  public allFilteredProperty: SimpleGridResponseViewModel<Product> | null;
  public allPropertyDiscreteTypes: string[] = [];
  public allPropertyDiscreteInitialPeriods: number[] = [];
  public allLenders: string[] = [];
  public auditResultsStatus: PropertyAuditResultsStatus = PropertyAuditResultsStatus.NotSubmitted;
  public propertyGrid: SimpleGridComponent | null;
  public resultsView: ResultsView = ResultsView.Details;
  public auditResultsCurrentStatus: PropertyAuditResultsStatus | null;
  public propertySearchId: string | undefined;
  public isAuditHistory: boolean;
  public requestHistory: unknown;
  public lenderList: string[] = [];
  public addresses: AddressExtended[] = [];
  public showAddress = false;
  public postcodeLookup = true;
  public hadValuesOriginally = false;

  public constructor(
    private criteriaService: CriteriaService,
    private igniteCommonDataService: IgniteCommonDataService,
    private igniteService: IgniteService,
    private lendingTypeService: LendingTypeService,
    private dataService: ClubHubDataService,
    private authService: AuthorizeService,
    private modalService: ModalService,
    public criteriaV2OutcomeService: CriteriaV2OutcomeService,
  ) {
    this.reset();
  }

  public reset() {
    (this.form.controls.propertyDetails as UntypedFormGroup)?.markAsUntouched();
    this.form = new UntypedFormGroup({});
    this.model = this.initialModel();
    this.currentStepName = StepName.MortgageRequirements;
    if (this.options.resetModel) {
      this.options.resetModel();
    }
    this.options = {};
    this.addresses = [];
    this.showAddress = false;
    this.postcodeLookup = true;
    this.igniteCommonDataService.propertyModel = this.model;
  }

  public resetParamsToDefault(): void {
    this.igniteCommonDataService.propertyModel = null;
    this.propertySearchId = undefined;
    this.propertyLoading = true;
    this.matchedLenders = [];
    this.propertyModelRequest = null;
    this.allFilteredProperty = null;
    this.allPropertyDiscreteTypes = [];
    this.allPropertyDiscreteInitialPeriods = [];
    this.allLenders = [];
    this.auditResultsStatus = PropertyAuditResultsStatus.NotSubmitted;
    this.propertyGrid = null;
    this.resultsView = ResultsView.Details;
    this.auditResultsCurrentStatus = null;
    this.isAuditHistory = false;
    this.requestHistory = null;
    this.lenderList = [];
    this.addresses = [];
    this.showAddress = false;
    this.postcodeLookup = true;
    this.hadValuesOriginally = false;
  }

  public getPropertyFormValue() {
    return this.form.value;
  }

  public get productsOnly() {
    return this.igniteService.productsOnly;
  }

  public get propertyOnly() {
    return this.igniteService.propertyOnly;
  }

  public get affordabilityOnly() {
    return this.igniteService.affordabilityOnly;
  }

  private get actualLendingType(): LendingType | undefined {
    if (this.igniteService.isSecondChargeProducts) {
      return this.lendingTypeService.getLendingTypeByCode('2nd');
    }

    return this.lendingTypeService.lendingType;
  }

  private get productTypeCode(): string | undefined {
    return this.actualLendingType?.code;
  }

  public get autoAddedProperty(): boolean {
    return this.igniteService.autoAddedPropertyOutcomeIds.length > 0;
  }

  public get isLender() {
    return this.authService.hasRole(roles.lender);
  }

  public get loading() {
    return this.igniteService.loading;
  }

  private initialModel(): IUiPropertySearchModel {
    this.hadValuesOriginally = false;
    const productModel = this.igniteCommonDataService.productModel;
    if (this.igniteCommonDataService.productsOnly && productModel) {
      const propertyValue = productModel.mortgageRequirements?.propertyValue ?? null;
      const loanAmount = productModel.mortgageRequirements?.loanAmount ?? null;

      this.hadValuesOriginally = !!propertyValue && !!loanAmount;
      return {
        propertyDetails: {
          propertyValue,
          loanAmount,
        },
      } as IUiPropertySearchModel;
    }

    return {} as IUiPropertySearchModel;
  }

  public getProperty() {
    this.propertyModelRequest = this.mappedModel(this.form.valid ? this.form.value : this.model);
    const propertySearchRequestViewModel = this.propertyModelRequest;
    this.igniteService.propertySearchRequestViewModel = propertySearchRequestViewModel;
    this.resultsView = ResultsView.Details;
    this.currentStepName = StepName.Results;
    // this.igniteService.loading = AffordabilityServiceLoadingViewMode.CriteriaOnly;
    this.criteriaService.criteriaLoading = true;
    setTimeout(() => {
      this.criteriaService.criteriaLoading  = false;
    }, 15000); // if you change the length of this timeout you will need to change it in the css animation too.

    const { postcode, address } = this.propertyModelRequest;
    this.dataService
      .post('propertyCriteria/FloodRisks', { postcode, address }, null, { showErrorToast: false })
      .subscribe({
        error: () => this.igniteService.floodRiskData = null,
        next: (response: FloodRiskData) => {
          this.igniteService.floodRiskData = Object.keys(response).length ? response : null;
        },
      });
    const endpoint = this.igniteService.criteriaV2Enabled ? 'propertyCriteriaV2' : 'propertyCriteria';

    // Clear property baskets before get new one
    this.criteriaV2OutcomeService.clearBasketProperty();

    this.dataService.post(endpoint, propertySearchRequestViewModel)
      .subscribe((result: PropertyOutcomeDetailsDetailed) => {
        this.igniteService.loading = AffordabilityServiceLoadingViewMode.None;
        this.propertySearchId = result.propertySearchId;
        this.igniteService.autoAddedPropertyOutcomeIds = result.autoAddedOutcomeIds ?? [];
        this.igniteService.setPropertyResults(result, this.propertySearchId, !this.propertyOnly);
        this.igniteService.resultsView = ResultsView.Summary;
        this.igniteService.lenders = result.details
          .map(x => ({ lenderName: x.overallResult.lender, lenderLogoUrl: x.overallResult.lenderLogoUrl }));
        this.igniteCommonDataService.searchToolsInUse.property = true;
      });

    this.modalService.closeAll();
  }

  public get isDetailsView() {
    return this.resultsView === ResultsView.Details;
  }

  public get isInputView() {
    return this.resultsView === ResultsView.Input;
  }

  public updateModel(model = {}) {
    this.model = { ...this.model, ...model };
  }

  private mappedModel(uiModel: IUiPropertySearchModel) {
    const {
      propertyDetails,
    } = uiModel;

    propertyDetails.address = propertyDetails.address ?? propertyDetails.manualAddress;

    if (this.affordabilityOnly) {
      propertyDetails.loanAmount = this.igniteService.model?.propertyAndLoan.loanAmount;
      propertyDetails.propertyValue = this.igniteService.model?.propertyAndLoan.propertyValue;
      propertyDetails.location = this.igniteService.model?.propertyAndLoan.location;
      propertyDetails.propertyType = this.igniteService.model?.propertyAndLoan.propertyType;
      propertyDetails.newBuild = this.igniteService.model?.propertyAndLoan.newBuild;
    }

    if (this.productsOnly) {
      const productModel = this.igniteCommonDataService.productModel;
      propertyDetails.loanAmount = productModel?.mortgageRequirements?.loanAmount;
      propertyDetails.propertyValue = productModel?.mortgageRequirements?.propertyValue;
    }

    return {
      ...propertyDetails,
      propertySearchId: this.propertySearchId,
      includeIntuitive: this.propertyOnly,
      productType: this.productTypeCode,
      affordabilitySearchId: this.propertyOnly ? null : this.igniteService.affordabilitySearchId,
    } as PropertyModelRequest;
  }

  public set auditResultsStatusChange(status: PropertyAuditResultsStatus) {
    this.auditResultsStatus = status;
  }

  public getPreviousOutcome(propertySearchId: string) {
    return this.dataService.get(`Property/outcome/${propertySearchId}`);
  }
}
