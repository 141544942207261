<div class="input-group disabled">
  <span
    *ngIf="fieldAddonStart"
    class="input-group-text"
    [ngClass]="fieldAddonStartClass"
  >
    {{ fieldAddonStart }}
  </span>

  <input
    class="form-control"
    [placeholder]="props.placeholder"
    disabled
    [value]="props.value"
    [attr.data-testid]="props.testId || field.key"
    />

  <span
    *ngIf="fieldAddonEnd"
    class="input-group-text"
    [ngClass]="fieldAddonEndClass"
  >
    {{ fieldAddonEnd }}
  </span>
</div>
